import type { AiQuestionStatus } from '../AIAdmin/AIAdmin.constants';

export type GenAIPanelProps = {
  readonly selectedCategory: HierNode;
  readonly configFiles: string[];
};

export type AIQuestionOption = {
  readonly id: string;
  readonly question: string;
  readonly question_status: AiQuestionStatus;
};

export type GenAIUserQuestion = {
  readonly question_id: string;
  readonly config_files: string[];
  readonly req_question_category: string;
};

export type GenAIAnswerActionsProps = {
  message: string;
  question: string;
};

export type GenAIFeedbackOption = {
  readonly options: string[];
};

export enum DIALOG_POST_AUTHOR {
  USER = 'user',
  AI = 'ai',
}

export enum AI_RESPONSE_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface AIPost {
  readonly requestId: string;
  readonly type: AI_RESPONSE_TYPE.SUCCESS | AI_RESPONSE_TYPE.ERROR;
  readonly message: string;
  readonly question: string;
}

type InfoPost = Pick<AIPost, 'message' | 'type'>;

type UserPost = Pick<AIPost, 'message'>;

export interface AIMessage {
  readonly owner: DIALOG_POST_AUTHOR.AI;
  readonly post: AIPost;
}

export interface UserMessage {
  readonly owner: DIALOG_POST_AUTHOR.USER;
  readonly post: UserPost;
}

export interface InfoMessage {
  readonly owner: DIALOG_POST_AUTHOR.AI;
  readonly post: InfoPost;
}
