export enum AiQuestionStatus {
  Published = 'published',
  Draft = 'draft',
  Testing = 'testing',
}

export const aiAdminTabsLabels = {
  published: 'Published',
  unpublished: 'Unpublished',
};

export const aiQuestionStatusNames: Record<AiQuestionStatus, string> = {
  [AiQuestionStatus.Published]: 'Published',
  [AiQuestionStatus.Draft]: 'Draft',
  [AiQuestionStatus.Testing]: 'Testing',
};

export const aiAdminTableHeaders = [
  { header: 'Question', key: 'question' },
  { header: 'Created By', key: 'modified_by' },
  { header: 'Status', key: 'question_status' },
  { header: 'Date Modified', key: 'last_updated_timestamp' },
  { header: 'Actions', key: 'actions' },
];

export const aiAminPublishedTableHeaders = [
  {
    header: '',
    key: 'order',
  },
  ...aiAdminTableHeaders,
];

export enum AiQuestionLinkedFiles {
  Product = 'kpi-tree-grid - Product',
  Location = 'kpi-tree-grid - Location',
  Sales = 'ckpi-chart - Sales Change',
  Units = 'ckpi-chart - Units Change',
}

export enum AIActionURL {
  Edit = 'edit',
  Duplicate = 'duplicate',
}

export const AI_INITIAL_PROMPT_VALUE = `I am going to provide you with data about the performance of a category in a grocery retailer.
It will also include data on how the subcategories in the category are performing.
There will also be data on the category performance by store groups.

========= Data format =========

The data with the column called Product contains the data for the Category and Subcategories. The Product column can be used for the Category and Subcategory names. The first row in the data is the category and the following rows are the Subcategories that are within the category. Each column refers to a metric.

The data with the column called Location contains the Category data for the Store groups. The Location column can be used for the Store Group names. Each row represents a different store group. Each column refers to a metric.

/* When using Contribution - include this description */

The data in the table is the sales value associated with the change in each of the metrics (columns). A negative sales value indicates that the metric contributed negatively to the product group sales. A positive sales value indicates that the metric contributed positively to the product group sales.

========= Measures Section =========

The data contains metrics that can be used to understand performance. The metrics include Sales, Baskets (or Transactions), Units, Members Shopped, Price Per Unit, Units Per Basket, Spend per Basket, Baskets Per Member, Spend Per Member, Member Penetration %, Member Spend Per Basket, Member Units per Basket and Member Price Per Unit.

The Retailer has both Member and Non-Member data. The metrics are grouped as following:
• Total metrics are based on the combined Member and Non-Member data
• Member metrics are based on the Member data only
• Non-Member metrics are based on the Non-Member data only
• Continuous Members metrics are based on Continuous Members. These are Members who shopped both this year and last year.
• New/Lost Members metrics are based on New/Lost Members. New Members didn't shop last year, but have shopped this year. Lost Members shopped last year, but haven't shopped this year.  

========= Ensuring Accuracy Section =========

Please ensure that the positive (growth) and negative (decline) changes in values are reflected 100% accurately in the summaries.
Please can you perform a QA sense check on the final output to validate the positive and negative values are correct. If there are mistakes in the output, can you help to correct the numbers and the analysis.
Do not hallucinate. Do not make up factual information.

========== Analysis tee up ===========

We have analysed the sales change over two comparable periods to understand where sales have increased and decreased, by Subcategory and Store Group.

I need your help to understand what is happening in my category. Start with a summary of the total category performance and then describe which of the subcategories below are the key drivers of performance, focused on subcategories that have had the biggest impact on Total Sales value. Please focus on where the largest changes in total value have occurred and help me understand which of the metrics have been the key drivers of the change. Talk in terms of why decline in a particular measure matters.

Combine different data points into single sentences to create a flowing story that cover both the change in performance for each of the subcategories and each of the store groups.

Please convert numbers to Millions with 1 Decimal place for large metric values.`;
