import type { FunctionComponent } from 'react';
import '../../styles/components/typingLoader.scss';

export const TypingLoader: FunctionComponent = () => {
  return (
    <div className="TypingLoader">
      <div className="TypingLoader__dot"></div>
      <div className="TypingLoader__dot"></div>
      <div className="TypingLoader__dot"></div>
    </div>
  );
};
