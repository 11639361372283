import { useContext, useState, useMemo, useRef, useEffect } from 'react';
import type {
  FormEvent,
  FunctionComponent,
  ReactNode,
  ChangeEvent,
} from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbarSearch,
  TableToolbarContent,
  TableBatchActions,
  TableBatchAction,
  Tag,
  NumberInput,
  IconButton,
  InlineLoading,
} from '@carbon/react';
import '../../styles/components/AIAdmin.scss';
import AI from '../../assets/icons/Ai';
import type { AIQuestion } from './AIAdmin.types';
import NoDataPlaceholder from '../ReportTable/NoDataPlaceholder';
import { useNavigate } from 'react-router-dom';
import {
  AIActionURL,
  aiAdminTableHeaders,
  aiAminPublishedTableHeaders,
  aiQuestionStatusNames,
} from './AIAdmin.constants';
import type { CarbonIconType } from '@carbon/icons-react';
import {
  Edit,
  Replicate,
  TrashCan,
  Save,
  WarningFilled,
} from '@carbon/icons-react';
import { apiRequest } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../providers/ModalProvider';
import { AIAdminContext } from '../../providers/AIAdminProvider';
import { CACHE_KEY } from '../../constants/api';
import { AppContext } from '../../providers/AppProvider';

interface Props {
  readonly questions: AIQuestion[];
  readonly isSortable?: boolean;
}

const AIQuestions: FunctionComponent<Props> = (props) => {
  const { questions, isSortable = false } = props;
  const initialQuestions = useRef<AIQuestion[]>([]);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { clearCacheForKey } = useContext(AppContext);
  const { toggleModal, updateModal } = useContext(ModalContext);
  const {
    removeAIQuestion,
    setAIQuestions,
    questions: allQuestions,
  } = useContext(AIAdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [deletingQuestionId, setDeletingQuestionId] = useState<string>('');
  const [reorderedQuestions, setReorderedQuestions] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    initialQuestions.current = allQuestions;
  }, []);

  const isOrderChanged: boolean = useMemo(() => {
    if (Object.keys(reorderedQuestions).length === 0) {
      return false;
    }

    return Object.entries(reorderedQuestions).some(([id, order]) =>
      initialQuestions.current.find(
        (question) => question.id === id && question.order !== order
      )
    );
  }, [initialQuestions, reorderedQuestions]);

  const isOrderValueValid = (value: number): boolean => {
    if (value <= 0 || value > questions.length || !Number.isInteger(value)) {
      return false;
    }

    return questions.filter(({ order }) => order === value).length < 2;
  };

  const isSubmitDisabled = !questions
    .map(({ order }) => isOrderValueValid(Number(order)))
    .every(Boolean);

  const handleDeleteClick = (id: string): void => {
    updateModal({
      type: 'warning',
      title: 'Delete AI Question',
      body: "You're about to delete this AI question, this action cannot be undone. Do you want to continue",
      primaryCTAText: 'Continue',
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: () => {
        toggleModal(false);
        handleQuestionDelete(id);
      },
    });
  };

  const handleQuestionDelete = async (id: string): Promise<void> => {
    try {
      setDeletingQuestionId(id);
      const token = await getAccessTokenSilently();
      await apiRequest(`admin/genai/questions/${id}`, 'DELETE', token);
      removeAIQuestion(id);
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    } finally {
      setDeletingQuestionId('');
    }
  };

  const handleReorderQuestions = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const payload: {
        id: string;
        order: number;
      }[] = Object.entries(reorderedQuestions).map(([id, order]) => ({
        id,
        order,
      }));
      const token = await getAccessTokenSilently();
      await apiRequest('admin/genai/questions', 'PATCH', token, payload);
      clearCacheForKey(CACHE_KEY.GENAI_QUESTIONS);
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelReorder = (): void => {
    setReorderedQuestions({});
    setAIQuestions(initialQuestions.current);
  };

  const handleOrderChange = (value: number, rowId: string): void => {
    setAIQuestions(
      allQuestions.map((question) => {
        if (question.id !== rowId) {
          return question;
        } else {
          return {
            ...question,
            order: value,
          };
        }
      })
    );
    setReorderedQuestions((prevQuestions) => ({
      ...prevQuestions,
      [rowId]: value || 0,
    }));
  };

  const getCellContent = ({
    row,
    cell,
  }: {
    row: CarbonSelectedRow;
    cell: CarbonCell<string>;
  }): ReactNode => {
    const aiAdminTableActions: {
      label: 'Edit' | 'Duplicate' | 'Delete';
      onClick: () => void;
      icon: CarbonIconType;
    }[] = [
      {
        label: 'Edit',
        onClick: () => navigate(`/ai-manager/${AIActionURL.Edit}/${row.id}`),
        icon: Edit,
      },
      {
        label: 'Duplicate',
        icon: Replicate,
        onClick: () =>
          navigate(`/ai-manager/${AIActionURL.Duplicate}/${row.id}`),
      },
      {
        label: 'Delete',
        icon: TrashCan,
        onClick: () => handleDeleteClick(row.id),
      },
    ];

    switch (cell.info.header) {
      case 'order': {
        return (
          <NumberInput
            id={cell.id}
            className="AIAdmin__table--order"
            size="lg"
            value={cell.value}
            min={1}
            max={questions.length}
            hideSteppers
            invalid={!isOrderValueValid(Number(cell.value))}
            onChange={(
              _: FormEvent<HTMLInputElement>,
              { value }: { value: number | string }
            ): void => handleOrderChange(Number(value), row.id)}
          />
        );
      }
      case 'question_status': {
        if (row.id === deletingQuestionId) {
          return <InlineLoading description="Deleting..." />;
        } else {
          const status = cell.value as AIQuestion['question_status'];
          const statusName = aiQuestionStatusNames[status];

          return (
            <div className="AIAdmin__table--status">
              <Tag size="sm" className={`status-tag ${status}`}>
                {statusName}
              </Tag>
            </div>
          );
        }
      }

      case 'actions': {
        return (
          <div className="AIAdmin__table--actions">
            {aiAdminTableActions.map(({ label, onClick, icon: Icon }) => (
              <IconButton
                data-testid={`${label}-${row.id}`}
                key={label}
                label={label}
                kind="ghost"
                size="sm"
                onClick={onClick}
                disabled={Boolean(deletingQuestionId)}
              >
                <Icon />
              </IconButton>
            ))}
          </div>
        );
      }

      case 'last_updated_timestamp': {
        return new Date(cell.value).toLocaleDateString();
      }

      default:
        return cell.value;
    }
  };

  const getNoDataPlaceholder = (): ReactNode =>
    questions.length > 0 ? (
      <NoDataPlaceholder
        title="No AI questions found"
        description={{
          info: 'Please refine your search and try again',
        }}
        icon={AI}
      />
    ) : (
      <NoDataPlaceholder
        title="No AI questions found"
        description={{
          info: 'It looks like you haven’t created any questions yet. To get started, please use the button below to create a new question.',
        }}
        icon={AI}
        buttonLabel="Create AI Question"
        onClick={() => navigate('/ai-manager/create')}
        buttonIcon={AI}
      />
    );

  return (
    <DataTable
      headers={isSortable ? aiAminPublishedTableHeaders : aiAdminTableHeaders}
      rows={questions}
    >
      {({
        rows,
        headers,
        getRowProps,
        getTableProps,
        getTableContainerProps,
        onInputChange,
      }: {
        rows: CarbonSelectedRow[];
        headers: Header[];
        getRowProps;
        getTableProps;
        getTableContainerProps;
        onInputChange: (event: ChangeEvent) => void;
      }) => (
        <TableContainer
          {...getTableContainerProps()}
          className={`AIAdmin__table--container ${isOrderChanged ? 'active-batch' : ''}`}
        >
          <TableToolbarSearch
            persistent
            size="md"
            className="AIAdmin__table--search"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onInputChange(event)
            }
          />
          <TableToolbarContent>
            <TableBatchActions
              onCancel={handleCancelReorder}
              shouldShowBatchActions={isOrderChanged}
              totalSelected={0}
              className="AIAdmin__table--batch"
            >
              <h5 className="AIAdmin__table--batch__title">
                Edit order
                {isSubmitDisabled && (
                  <span className="AIAdmin__table--batch__warning">
                    <WarningFilled />
                    Each question must be numbered from 1 to {questions.length}.
                    Please ensure the order is complete and correct.
                  </span>
                )}
              </h5>
              <div className="AIAdmin__table--batch__actions">
                <TableBatchAction
                  renderIcon={isLoading ? InlineLoading : Save}
                  onClick={handleReorderQuestions}
                  disabled={isLoading || isSubmitDisabled}
                >
                  Save
                </TableBatchAction>
              </div>
            </TableBatchActions>
          </TableToolbarContent>
          {rows.length > 0 ? (
            <Table {...getTableProps()} className="AIAdmin__table">
              <TableHead>
                <TableRow>
                  {headers.map(({ header, key }) => (
                    <TableHeader key={key}>{header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {getCellContent({ row, cell })}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            getNoDataPlaceholder()
          )}
        </TableContainer>
      )}
    </DataTable>
  );
};

export default AIQuestions;
