/* eslint-disable indent */
import type { ChangeEvent, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Search, Dropdown, Checkbox } from '@carbon/react';
import {
  Upload,
  TrashCan,
  Locked,
  Unlocked,
  SettingsAdjust,
} from '@carbon/icons-react';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks';
import { CustomGroupsContext } from '../../../providers/CustomGroupsProvider';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../../api';
import { AppContext } from '../../../providers/AppProvider';
import Fetch from '../../Fetch';
import { ModalContext } from '../../../providers/ModalProvider';
import { CACHE_KEY } from '../../../constants/api';
import usePosthog from '../../../utils/posthog';
import {
  custGroupsUploadBtnClick,
  customGroupDropdownSelection,
  deleteExistingCustGroupFailure,
  deleteExistingCustGroupSuccess,
  searchCustomGroupClick,
  updateCustomGroupFailure,
  updateCustomGroupSuccess,
} from '../../../constants/posthog';
import {
  CUSTOM_GROUP_ALL_GROUPS,
  CustomGroupAccess,
} from '../../../constants/metadata';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import CustomGroupItem from './CustomGroupItem';
import NoCustomGroupsPlaceholder from '../NoCustomGroupsPlaceholder/NoCustomGroupsPlaceholder';
import '../../../styles/components/CustomGroups.scss';
import type { ExistingCustGroup } from '../../../reducers/CustomGroupsReducer';
import IconTitle from '../../IconTitle';

export interface UpdateCustomGroupParams {
  customGroupIds: string[];
  targetValue?: string;
  currentAccess?: string;
}
export const renderCustomGroupsMetadata = (group) => {
  if (!group.created_timestamp) {
    return null;
  }

  return (
    <div>
      <div className="CustomGroups__item--info">
        <div>
          {`Created: ${DateTime.fromISO(group.created_timestamp).toFormat(
            'dd/LL/yyyy'
          )}`}

          {group.updated_timestamp && (
            <>
              <span className="CustomGroups__item--divider">|</span>
              {`Last Change: ${DateTime.fromISO(
                group.updated_timestamp
              ).toFormat('dd/LL/yyyy')}`}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CustomGroups: FunctionComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { bannerId, groupId } = useContext(AppContext);
  const {
    existingCustGroups,
    updateFileSpecs,
    updateUploadStep,
    updateUploadData,
    updateExistingCustGroups,
    updateSelectedCustomGroupId,
  } = useContext(CustomGroupsContext);
  const posthogEvent = usePosthog();
  const { toggleModal, updateModal } = useContext(ModalContext);
  const [filteredCustGroups, setFilteredCustGroups] = useState<
    ExistingCustGroup[]
  >([]);
  const [deletingItems, setDeletingItems] = useState<string[]>([]);
  const [updatingItems, setUpdatingItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [invalidSearchTerm, setInvalidSearchTerm] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(CUSTOM_GROUP_ALL_GROUPS);
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    updateUploadStep(0);
    updateFileSpecs([]);
    updateUploadData([]);
    return () => {
      updateSelectedCustomGroupId('');
    };
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const matchedGroups = filteredCustGroups?.filter((gr) =>
        gr.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredCustGroups(matchedGroups);
      setInvalidSearchTerm(matchedGroups.length === 0);
      posthogEvent(searchCustomGroupClick, {
        searchValue: debouncedSearchTerm,
      });
    } else {
      dropdownValue !== CUSTOM_GROUP_ALL_GROUPS
        ? setFilteredCustGroups(
            existingCustGroups.filter(
              (gr: ExistingCustGroup) =>
                gr.dimension === dropdownValue.toLowerCase()
            )
          )
        : setFilteredCustGroups(existingCustGroups);
      setInvalidSearchTerm(false);
    }
  }, [debouncedSearchTerm]);

  const handleUploadClick = () => {
    posthogEvent(custGroupsUploadBtnClick);
    navigate('upload');
  };
  const deleteCustomGroup = async (customGroupIds: string[]): Promise<void> => {
    const groupText =
      customGroupIds.length > 1
        ? { main: 'these', secondary: 'them', suffix: 's' }
        : { main: 'this', secondary: 'it', suffix: '' };

    updateModal({
      type: 'warning',
      title: `Delete Group${groupText.suffix}`,
      body: `Are you sure you want to delete ${groupText.main} group${groupText.suffix}? Deleting ${groupText.secondary} will remove all content and deny access to all users. This action cannot be undone.`,
      primaryCTAText: 'Delete',
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: async () => {
        toggleModal(false);
        setDeletingItems(customGroupIds);
        setSelectAll(false);
        const token = await getAccessTokenSilently();
        const payload = {
          ids: customGroupIds,
        };

        try {
          await apiRequest(
            `/configs/${bannerId}/user-groups/${groupId}/hierarchies`,
            'DELETE',
            token,
            payload
          );

          posthogEvent(deleteExistingCustGroupSuccess, {
            customGroupIds,
          });
        } catch {
          posthogEvent(deleteExistingCustGroupFailure, {
            customGroupIds,
          });
          updateModal({
            type: 'error',
            title: 'Something went wrong',
            body: 'There was an error deleting custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
          });
        } finally {
          setDeletingItems([]);
          const deletedGroups = existingCustGroups?.filter(
            (gr: ExistingCustGroup) => !customGroupIds.includes(gr.id)
          );
          updateExistingCustGroups(deletedGroups);
          filteredCustGroups.length > 0 &&
            setFilteredCustGroups(
              deletedGroups.filter(
                (gr: ExistingCustGroup) =>
                  gr.dimension === dropdownValue.toLowerCase()
              )
            );
        }
      },
    });
  };

  useEffect(() => {
    if (existingCustGroups) {
      setFilteredCustGroups(
        dropdownValue !== CUSTOM_GROUP_ALL_GROUPS
          ? existingCustGroups.filter(
              (gr: ExistingCustGroup) =>
                gr.dimension === dropdownValue.toLowerCase()
            )
          : existingCustGroups
      );
    } else {
      setFilteredCustGroups([]);
    }
  }, [existingCustGroups, searchTerm]);

  const updateCustomGroup = async ({
    customGroupIds,
    targetValue,
    currentAccess,
  }: UpdateCustomGroupParams): Promise<void> => {
    const newAccess =
      currentAccess === CustomGroupAccess.PUBLIC
        ? CustomGroupAccess.PRIVATE
        : CustomGroupAccess.PUBLIC;
    const updatedGroupProperty = currentAccess ? 'access' : 'name';
    const updatedPropertyValue = currentAccess ? newAccess : targetValue;

    const updateGroup = async () => {
      setUpdatingItems(customGroupIds);

      const token = await getAccessTokenSilently();
      const HIERARCHIES_URL = `/configs/${bannerId}/user-groups/${groupId}/hierarchies`;
      const requestUrl = currentAccess
        ? HIERARCHIES_URL
        : `${HIERARCHIES_URL}/${customGroupIds[0]}`;
      const payload = currentAccess
        ? { access: newAccess, ids: customGroupIds }
        : { group_name: targetValue };

      try {
        await apiRequest(requestUrl, 'PATCH', token, payload);

        posthogEvent(updateCustomGroupSuccess, payload);
      } catch {
        posthogEvent(updateCustomGroupFailure, payload);
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an error updating custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        });
      } finally {
        setUpdatingItems([]);
        const updatedGroups = existingCustGroups?.map((gr: ExistingCustGroup) =>
          customGroupIds.includes(gr.id)
            ? { ...gr, [updatedGroupProperty]: updatedPropertyValue }
            : gr
        );
        updateExistingCustGroups(updatedGroups);
        setSearchTerm('');
      }
    };
    const groupText =
      customGroupIds.length > 1
        ? { main: 'these', secondary: 'them', suffix: 's' }
        : { main: 'this', secondary: 'it', suffix: '' };

    const modalBody =
      newAccess === CustomGroupAccess.PRIVATE
        ? `This action will make your group${groupText.suffix} private so only you can view and manage ${groupText.secondary}. Do you want to proceed?`
        : `Making your group${groupText.suffix} public allows all users in the user group to see and use ${groupText.secondary} in the report builder. Do you want to proceed?`;
    if (currentAccess) {
      updateModal({
        type: 'warning',
        title: `Set Group${groupText.suffix} to ${newAccess}`,
        body: modalBody,

        primaryCTAText: `Make ${newAccess}`,
        secondaryCTAText: 'Cancel',
        onPrimaryCTAClick: async () => {
          setSelectAll(false);
          toggleModal(false);
          await updateGroup();
        },
        onSecondaryCTAClick: () => toggleModal(false),
      });
    } else {
      await updateGroup();
    }
  };

  const dimensionTypes = [
    CUSTOM_GROUP_ALL_GROUPS,
    ...new Set(
      existingCustGroups.map((item: ExistingCustGroup) =>
        capitalizeFirstLetter(item.dimension)
      )
    ),
  ];

  const checkedIds = filteredCustGroups.reduce(
    (acc, item) => (item.checked ? [...acc, item.id] : acc),
    [] as string[]
  );
  const isDisabled = updatingItems.length > 0 || deletingItems.length > 0;
  const bulkActions = [
    {
      name: CustomGroupAccess.PRIVATE,
      icon: Locked,
      divider: true,
      disabled: isDisabled,
      onClick: () =>
        updateCustomGroup({
          customGroupIds: checkedIds,
          targetValue: '',
          currentAccess: CustomGroupAccess.PUBLIC,
        }),
    },
    {
      name: CustomGroupAccess.PUBLIC,
      icon: Unlocked,
      divider: true,
      disabled: isDisabled,
      onClick: () =>
        updateCustomGroup({
          customGroupIds: checkedIds,
          targetValue: '',
          currentAccess: CustomGroupAccess.PRIVATE,
        }),
    },
    {
      name: 'Delete',
      icon: TrashCan,
      divider: true,
      disabled: isDisabled,
      onClick: () => deleteCustomGroup(checkedIds),
    },
    {
      name: 'Cancel',
      icon: null,
      divider: false,
      disabled: false,
      onClick: () => {
        setSelectAll(false);
        setFilteredCustGroups(
          filteredCustGroups.map((gr) => ({
            ...gr,
            checked: false,
          }))
        );
      },
    },
  ];

  const handleSelectAllCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    { checked }: { checked: boolean }
  ): void => {
    const checkedFilteredCustGroups = filteredCustGroups.map((gr) => ({
      ...gr,
      checked,
    }));
    setFilteredCustGroups(checkedFilteredCustGroups);
    setSelectAll(checked);
  };

  const handleDropdownChange = ({
    selectedItem,
  }: {
    selectedItem: string;
  }): void => {
    if (!existingCustGroups) {
      return;
    }
    posthogEvent(customGroupDropdownSelection, {
      selectedItem,
    });
    setSearchTerm('');

    if (selectedItem !== CUSTOM_GROUP_ALL_GROUPS) {
      setFilteredCustGroups(
        existingCustGroups.filter(
          (gr: ExistingCustGroup) => gr.dimension === selectedItem.toLowerCase()
        )
      );
    } else {
      setFilteredCustGroups(existingCustGroups);
    }

    setDropdownValue(selectedItem);
  };

  return (
    <>
      <div className="CustomGroups__title-section">
        <div className="CustomGroups__title-wrapper">
          <div className="CustomGroups__title">
            <div className="CustomGroups__label-wrapper">
              <IconTitle
                icon={<SettingsAdjust size={20} />}
                title="Custom Groups"
                kind="primary"
              />
            </div>
            <div className="CustomGroups__title-helper">
              Custom groups can be added as a Group, Filter, or Universe in the
              Report Builder
            </div>
          </div>
        </div>
        {existingCustGroups && existingCustGroups.length > 0 && (
          <Button
            kind="primary"
            size="md"
            renderIcon={Upload}
            className="has-icon"
            onClick={() => handleUploadClick()}
            data-testid="upload-group-btn"
          >
            Upload Group
          </Button>
        )}
      </div>
      <div className="CustomGroups">
        <Fetch
          alwaysFetchOnMount
          key="custom-groups"
          apiUrl={`/configs/${bannerId}/user-groups/${groupId}/hierarchies?view=custom`}
          initialData={null}
          loadingMessage="Loading all custom groups..."
          cacheKey={CACHE_KEY.CUSTOM_GROUPS}
          onReceiveData={(data: ExistingCustGroup[] | null | undefined) => {
            if (data) {
              updateExistingCustGroups(
                data.map((item) => ({ ...item, checked: false }))
              );
            }
          }}
        >
          <div className="CustomGroups__section">
            {existingCustGroups && existingCustGroups.length > 0 ? (
              <>
                <div className="CustomGroups__filter-wrapper">
                  <div className="CustomGroups__filter">
                    <Search
                      className="CustomGroups__filter-search"
                      id="custom-groups-search"
                      data-testid="custom-groups-search"
                      placeholder="Search custom groups name, type"
                      labelText="Search"
                      type="text"
                      value={searchTerm}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <Dropdown
                      id="custom-groups-dropdown"
                      label="label"
                      items={dimensionTypes}
                      initialSelectedItem={dimensionTypes[0]}
                      onChange={handleDropdownChange}
                    />
                  </div>

                  {filteredCustGroups?.some((gr) => gr.checked) && (
                    <div>
                      <div className="CustomGroups__bulk-actions">
                        {bulkActions.map((action) => {
                          const { name, divider, icon, disabled, onClick } =
                            action;
                          return (
                            <div
                              className="CustomGroups__bulk-actions-button-wrapper"
                              key={name}
                            >
                              <div className="CustomGroups__bulk-actions-button">
                                <Button
                                  className={icon ? 'has-icon' : ''}
                                  type="action"
                                  kind="tertiary"
                                  disabled={disabled}
                                  onClick={onClick}
                                  renderIcon={icon}
                                  data-testid={`custom-groups-bulk-action-${name.toLocaleLowerCase()}`}
                                  size="md"
                                >
                                  {name}
                                </Button>
                                {divider && (
                                  <div className="CustomGroups__bulk-actions-divider" />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <Checkbox
                    key="select-all-custom-groups-checkbox"
                    labelText="Select All Custom Groups"
                    id="select-all-custom-groups-checkbox"
                    data-testid="select-all-custom-groups-checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllCheckbox}
                  />
                </div>
                {!invalidSearchTerm ? (
                  filteredCustGroups?.map((group) => (
                    <CustomGroupItem
                      key={group.id}
                      group={group}
                      updateCustomGroup={updateCustomGroup}
                      deletingItems={deletingItems}
                      filteredCustGroups={filteredCustGroups}
                      setFilteredCustGroups={setFilteredCustGroups}
                      updatingItems={updatingItems}
                      deleteCustomGroup={deleteCustomGroup}
                    />
                  ))
                ) : (
                  <div className="body-emphasis-03">
                    No results found with “{debouncedSearchTerm}”. Please try
                    another search.
                  </div>
                )}
              </>
            ) : (
              <NoCustomGroupsPlaceholder />
            )}
          </div>
        </Fetch>
      </div>
    </>
  );
};

export default CustomGroups;
