export const INITIALISE_APP_DATA = 'INITIALISE_APP_DATA';
export const UPDATE_DIMENSIONS_DATA = 'UPDATE_DIMENSIONS_DATA';
export const UPDATE_REPORT_PARAMETERS = 'UPDATE_REPORT_PARAMETERS';
export const UPDATE_HIERARCHY_DATA = 'UPDATE_HIERARCHY_DATA';
export const UPDATE_FAVOURITE_GROUPS = 'UPDATE_FAVOURITE_GROUPS';
export const UPDATE_CALENDAR_DATA = 'UPDATE_CALENDAR_DATA';
export const UPDATE_REPORT_MODULES = 'UPDATE_REPORT_MODULES';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_GROUP_ID = 'UPDATE_USER_GROUP_ID';
export const UPDATE_QUERY_CACHE = 'UPDATE_QUERY_CACHE';
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR';
export const UPDATE_REPORT_CONFIG = 'UPDATE_REPORT_CONFIG';
export const UPDATE_TAB_INDEX = 'UPDATE_TAB_INDEX';
export const UPDATE_VISUALS_DATA = 'UPDATE_VISUALS_DATA';
export const PATCH_VISUALS_DATA = 'PATCH_VISUALS_DATA';
export const TOGGLE_VISUAL_FULLSCREEN = 'TOGGLE_VISUAL_FULLSCREEN';
export const RESET_ALL_REPORT_DATA = 'RESET_ALL_REPORT_DATA';

export const UPDATE_SELECTED_POINTS = 'UPDATE_SELECTED_POINTS';
export const UPDATE_CHART_OPTIONS = 'UPDATE_CHART_OPTIONS';
export const UPDATE_MEASURE = 'UPDATE_MEASURE';

export const UPDATE_SINGLE_SELECTION = 'UPDATE_SINGLE_SELECTION';
export const UPDATE_DROPDOWN_SELECTIONS = 'UPDATE_DROPDOWN_SELECTIONS';
export const UPDATE_SHARED_DROPDOWNS = 'UPDATE_SHARED_DROPDOWNS';
export const UPDATE_FULL_SCREEN_REF = 'UPDATE_FULL_SCREEN_REF';
export const UPDATE_TREE_CHART = 'UPDATE_TREE_CHART';
export const TOGGLE_SECONDARY_AXIS = 'TOGGLE_SECONDARY_AXIS';
export const UPDATE_SECONDARY_AXIS_SELECTION =
  'UPDATE_SECONDARY_AXIS_SELECTION';
export const UPDATE_DATA_FILES = 'UPDATE_DATA_FILES';
export const UPDATE_FILE_DROPDOWN_SELECTION = 'UPDATE_FILE_DROPDOWN_SELECTION';
export const UPDATE_FILES_DROPDOWN_SELECTIONS =
  'UPDATE_FILES_DROPDOWN_SELECTIONS';
export const SET_VISUAL_CONTROLS_REF = 'SET_VISUAL_CONTROLS_REF';

export const TOGGLE_SIDE_PANEL_EXPAND = 'TOGGLE_SIDE_PANEL_EXPAND';
export const RENDER_SIDE_PANEL_CONTENT = 'RENDER_SIDE_PANEL_CONTENT';
export const RESIZE_SIDE_PANEL = 'RESIZE_SIDE_PANEL';

export const INITIAL_LOAD = 'INITIAL_LOAD';
export const UPDATE_CDT_ROOT_NAME = 'UPDATE_CDT_ROOT_NAME';
export const UPDATE_ASSORTMENT_DATA = 'UPDATE_ASSORTMENT_DATA';
export const UPDATE_LAST_SAVED_DATA = 'UPDATE_LAST_SAVED_DATA';
export const UPDATE_ALL_OPTIMISER_DATA = 'UPDATE_ALL_OPTIMISER_DATA';
export const UPDATE_OPTIMISER_ROWS = 'UPDATE_OPTIMISER_ROWS';
export const UPDATE_OPTIMISER_STRATEGIES = 'UPDATE_OPTIMISER_STRATEGIES';
export const UPDATE_CDT_NODES = 'UPDATE_CDT_NODES';
export const TOGGLE_SAVE_STATE = 'TOGGLE_SAVE_STATE';
export const UPDATE_SAVING_MESSAGE = 'UPDATE_SAVING_MESSAGE';
export const UPDATE_SELECTED_PRODUCTS = 'UPDATE_SELECTED_PRODUCTS';
export const UPDATE_SELECTED_NODE = 'UPDATE_SELECTED_NODE';
export const RESET_SELECTION = 'RESET_SELECTION';
export const UPDATING_OPTIMISATION = 'UPDATING_OPTIMISATION';
export const UPDATING_OPTIMISATION_STATUS = 'UPDATING_OPTIMISATION_STATUS';
export const VALIDATE_OPTIMISER = 'VALIDATE_OPTIMISER';
export const UPDATE_HIGHLIGHT = 'UPDATE_HIGHLIGHT';
export const CLEAR_HIGHLIGHT = 'CLEAR_HIGHLIGHT';

export const LOAD_OPTIMISER_DATA = 'LOAD_OPTIMISER_DATA';
export const UPDATE_STRATEGY = 'UPDATE_STRATEGY';
export const UPDATE_NEW_PRODUCTS = 'UPDATE_NEW_PRODUCTS';
export const UPDATE_CLUSTER_GOALS = 'UPDATE_CLUSTER_GOALS';
export const UPDATE_OPTIMISER_MODE = 'UPDATE_OPTIMISER_MODE';
export const UPDATE_PLAN_INCLUSION = 'UPDATE_PLAN_INCLUSION';
export const UPDATE_PLAN_PLANOGRAM = 'UPDATE_PLAN_PLANOGRAM';
export const UPDATE_PLAN_TARGET = 'UPDATE_PLAN_TARGET';

export const UPDATE_MODAL_OPTIONS = 'UPDATE_MODAL_OPTIONS';
export const RENDER_COMPONENT = 'RENDER_COMPONENT';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const CHANGE_SCHEDULE_OPTION = 'CHANGE_SCHEDULE_OPTION';
export const CLEAR_SCHEDULE_STATE = 'CLEAR_SCHEDULE_STATE';
export const SET_INITIAL_SCHEDULE_STATE = 'SET_INITIAL_SCHEDULE_STATE';

export const CHANGE_FILTER = 'CHANGE_FILTER';
export const SET_INITIAL_FILTER_STATE = 'SET_INITIAL_FILTER_STATE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_TAB_FILTERS = 'RESET_TAB_FILTERS';
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST';

export const UPDATE_UPLOAD_STEP = 'UPDATE_UPLOAD_STEP';
export const UPDATE_UPLOAD_DATA = 'UPDATE_UPLOAD_DATA';
export const UPDATE_FILE_SPECS = 'UPDATE_FILE_SPECS';
export const UPDATE_FILTER_BY_CUSTOM_GROUP = 'UPDATE_FILTER_BY_CUSTOM_GROUP';
export const UPDATE_UPLOAD_CUSTOM_GROUPS = 'UPDATE_UPLOAD_CUSTOM_GROUPS';
export const UPDATE_EXISTING_CUSTOM_GROUPS = 'UPDATE_EXISTING_CUSTOM_GROUPS';
export const UPDATE_HAS_ENTITY_ERRORS = 'UPDATE_HAS_ENTITY_ERRORS';
export const UPDATE_SELECTED_DIMENSION_GROUP =
  'UPDATE_SELECTED_DIMENSION_GROUP';
export const UPDATE_SELECTED_CUSTOM_GROUP_ID =
  'UPDATE_SELECTED_CUSTOM_GROUP_ID';
export const RESET_UPLOAD_DATA = 'RESET_UPLOAD_DATA';

export const UPDATE_ADMIN_PANEL_USERS_LIST = 'UPDATE_ADMIN_PANEL_USERS_LIST';
export const UPDATE_CONFIG_DATA = 'UPDATE_CONFIG_DATA';
export const UPDATE_USER_FORM = 'UPDATE_USER_FORM';
export const UPDATE_USER_FORM_ERROR = 'UPDATE_USER_FORM_ERROR';
export const RESET_USER_FORM = 'RESET_USER_FORM';
export const UPDATE_USERS_TABLE_FILTERS = 'UPDATE_USERS_TABLE_FILTERS';
export const CLEAR_USERS_TABLE_FILTERS = 'CLEAR_USERS_TABLE_FILTERS';
export const UPDATE_USER_FORM_BANNERS = 'UPDATE_USER_FORM_BANNERS';
export const ADD_NEW_EMPTY_BANNER = 'ADD_NEW_EMPTY_BANNER';
export const REMOVE_BANNER_FROM_LIST = 'REMOVE_BANNER_FROM_LIST';

export const UPDATE_ATTRIBUTE_VALUES_FILE_SPECS =
  'UPDATE_ATTRIBUTE_VALUES_FILE_SPECS';
export const UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA =
  'UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA';
export const UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA =
  'UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA';
export const RESET_ATTRIBUTE_VALUES_UPLOAD_DATA =
  'RESET_ATTRIBUTE_VALUES_UPLOAD_DATA';

export const UPDATE_AI_ADMIN_QUESTION_FORM = 'UPDATE_AI_ADMIN_QUESTION_FORM';
export const RESET_AI_ADMIN_QUESTION_FORM = 'RESET_AI_ADMIN_QUESTION_FORM';
export const SET_AI_ADMIN_QUESTION_FORM = 'SET_AI_ADMIN_QUESTION_FORM';

export const UPDATE_AI_ADMIN_QUESTIONS = 'UPDATE_AI_ADMIN_QUESTIONS';
export const REMOVE_AI_ADMIN_QUESTION = 'REMOVE_AI_ADMIN_QUESTION';

export const UPDATE_NPD_ROW_FILE_DATA = 'UPDATE_NPD_ROW_FILE_DATA';
export const UPDATE_NPD_ROWS = 'UPDATE_NPD_ROWS';
export const RESET_NPD_ROW_SELECTION = 'RESET_NPD_ROW_SELECTION';
export const ADD_TO_BENCHMARK_UNSELECTED_ROWS =
  'ADD_TO_BENCHMARK_UNSELECTED_ROWS';
export const UPDATE_NPD_FINDER_SAVE_STATE = 'UPDATE_NPD_FINDER_SAVE_STATE';
