import { useEffect } from 'react';
import ReportProvider from '../../providers/ReportProvider';
import ReportContent from './ReportContent';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/viewReport.scss';
import { useFeatureFlagEnabled } from '../../context/posthog';

const ViewReport = () => {
  const navigate = useNavigate();
  const isMaintenanceActive = useFeatureFlagEnabled('workspace-maintenance');

  useEffect(() => {
    if (isMaintenanceActive) {
      navigate('/workspace/my-reports');
    }
  }, [isMaintenanceActive]);

  return (
    <ReportProvider>
      <ReportContent />
    </ReportProvider>
  );
};

export default ViewReport;
