import type { FunctionComponent } from 'react';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import {
  ChevronDown,
  CicsSitOverrides as EditedIcon,
} from '@carbon/icons-react';

interface CellDropdownProps {
  selectedValue: string;
  hasChanges?: boolean;
  options: string[] | EditableDropdownOption[];
  editable: boolean;
  disabled?: boolean;
  onSelectValue: (newSelection: string) => void;
}

const CellDropdown: FunctionComponent<CellDropdownProps> = ({
  selectedValue,
  hasChanges,
  options,
  editable,
  disabled,
  onSelectValue,
}) => {
  return (
    <div
      className={`DataTable__CellDropdown${
        hasChanges ? ' DataTable__CellDropdown--dirty' : ''
      }`}
    >
      {hasChanges && <EditedIcon aria-label="Unsaved changes" />}
      <span>{selectedValue}</span>
      {editable && (
        <OverflowMenu
          flipped
          renderIcon={ChevronDown}
          size="sm"
          data-testid="cell-dropdown-menu"
          ariaLabel="Dropdown menu"
          disabled={disabled}
        >
          {options.map((option) => {
            const value = typeof option === 'string' ? option : option.value;

            return (
              <OverflowMenuItem
                key={value}
                itemText={value}
                onClick={() => onSelectValue(option)}
              />
            );
          })}
        </OverflowMenu>
      )}
    </div>
  );
};

export default CellDropdown;
