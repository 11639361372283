import { useEffect } from 'react';
import { Workspace as WorkspaceIcon } from '@carbon/icons-react';
import { useFeatureFlagPayload } from '../context/posthog';
import Maintenance from './Maintenance/Maintenance';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportFilterProvider from '../providers/ReportFilterProvider';
import TabsContainer from './TabsContainer';
import IconTitle from './IconTitle';
import { workspaceTabs } from '../constants/metadata';

export enum REPORT_STATUS {
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  NO_DATA = 'Completed with no data',
  CANCELLED = 'Cancelled',
  SCHEDULED = 'Scheduled',
  CANCELLED_SCHEDULE = 'Cancelled schedule',
}

const Workspace = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const workspaceMaintenanceFeatureFlag = useFeatureFlagPayload(
    'workspace-maintenance'
  );

  useEffect(() => {
    if (pathname === '/workspace' || pathname === '/workspace/') {
      navigate('/workspace/my-reports', { replace: true });
    }
  }, [pathname]);

  if (workspaceMaintenanceFeatureFlag) {
    const { title, text, duration } = workspaceMaintenanceFeatureFlag;

    return (
      <Maintenance
        header={title}
        subheader={text}
        endTime={duration?.endTime}
      />
    );
  }

  const activeTab = workspaceTabs.findIndex(
    ({ path }) => path === pathname.split('/')[2]
  );

  return (
    <>
      <IconTitle
        icon={<WorkspaceIcon size={20} />}
        title="My Workspace"
        kind="primary"
      />

      <div className="workspace-container">
        <ReportFilterProvider>
          <TabsContainer activeTab={activeTab} tabs={workspaceTabs} />
        </ReportFilterProvider>
      </div>
    </>
  );
};

export default Workspace;
