/* eslint-disable indent */
import type { RowDataDefaultType } from '../components/Charts/DataGrid';
import {
  UPDATE_NPD_FINDER_SAVE_STATE,
  UPDATE_NPD_ROW_FILE_DATA,
  UPDATE_NPD_ROWS,
  RESET_NPD_ROW_SELECTION,
  ADD_TO_BENCHMARK_UNSELECTED_ROWS,
} from '../constants/reducers';
import type { VisualData } from './ReportReducer';

export enum NPDProductSelection {
  UNSELECTED = 'unselected',
  FOCUS = 'focus',
  BENCHMARK = 'bench',
  EXCLUDED = 'excl',
}

export interface NPDCard {
  focusProducts: number;
  benchmarkProducts: number;
}

export interface NPDGridRowDataType extends RowDataDefaultType {
  SELECTION: { type: NPDProductSelection; value: string };
}

export interface NPDFileData {
  readonly rows: {
    readonly [key: string]: NPDGridRowDataType[];
  };
}

export interface NPDState {
  readonly files: {
    readonly [fileKey: string]: NPDFileData;
  };
  readonly isSaving: boolean;
  readonly isSaveComplete: boolean;
}

interface UpdateNPDRowData {
  type: typeof UPDATE_NPD_ROW_FILE_DATA;
  rows: NPDGridRowDataType[];
  fileKey: string;
  dropdownKey: string;
}

interface UpdateNPDRows {
  type: typeof UPDATE_NPD_ROWS;
  fileKey: string;
  rowData: NonNullable<VisualData<NPDGridRowDataType>['rows']>;
}

interface ResetNPDRowSelection {
  type: typeof RESET_NPD_ROW_SELECTION;
  fileKey: string;
  dropdownKey: string;
}

interface AddToBenchmarkUnselectedRows {
  type: typeof ADD_TO_BENCHMARK_UNSELECTED_ROWS;
  fileKey: string;
  dropdownKey: string;
}

interface UpdateSaveState {
  type: typeof UPDATE_NPD_FINDER_SAVE_STATE;
  isSaving: boolean;
  isSaveComplete: boolean;
}

export type NPDAction =
  | UpdateNPDRowData
  | UpdateNPDRows
  | UpdateSaveState
  | ResetNPDRowSelection
  | AddToBenchmarkUnselectedRows;

export const initialNPDState: NPDState = {
  files: {},
  isSaving: false,
  isSaveComplete: true,
};

const NPDReducer = (state: NPDState, action: NPDAction): NPDState => {
  switch (action.type) {
    case UPDATE_NPD_ROW_FILE_DATA: {
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileKey]: {
            rows: {
              ...state.files[action.fileKey]?.rows,
              [action.dropdownKey]: action.rows,
            },
          },
        },
        isSaveComplete: false,
      };
    }

    case UPDATE_NPD_ROWS: {
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileKey]: {
            rows: action.rowData,
          },
        },
      };
    }

    case UPDATE_NPD_FINDER_SAVE_STATE: {
      return {
        ...state,
        isSaving: action.isSaving,
        isSaveComplete: action.isSaveComplete,
      };
    }

    case RESET_NPD_ROW_SELECTION: {
      const rows = state.files[action.fileKey]?.rows?.[action.dropdownKey];
      const newRows = rows.map((row) => ({
        ...row,
        SELECTION: {
          type: NPDProductSelection.UNSELECTED,
          value: 'Unselected',
        },
      }));
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileKey]: {
            rows: {
              ...state.files[action.fileKey]?.rows,
              [action.dropdownKey]: newRows,
            },
          },
        },
        isSaveComplete: false,
      };
    }

    case ADD_TO_BENCHMARK_UNSELECTED_ROWS: {
      const rows = state.files[action.fileKey]?.rows?.[action.dropdownKey];
      const newRows = rows.map((row) =>
        row.SELECTION.type === NPDProductSelection.UNSELECTED
          ? {
              ...row,
              SELECTION: {
                type: NPDProductSelection.BENCHMARK,
                value: 'Benchmark',
              },
            }
          : row
      );
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileKey]: {
            rows: {
              ...state.files[action.fileKey]?.rows,
              [action.dropdownKey]: newRows,
            },
          },
        },
        isSaveComplete: false,
      };
    }

    default:
      return state;
  }
};

export default NPDReducer;
