import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import posthog from 'posthog-js';
import type { FeatureFlag } from '../../types/featureFlag';
import '../../styles/components/posthogProvider.scss';

export interface PosthogContextType {
  readonly featureFlags: FeatureFlag[];
}

export const PosthogContext = createContext<PosthogContextType>(
  {} as PosthogContextType
);

interface PosthogProviderProps {
  readonly children?: ReactNode;
}

const PosthogProvider: FunctionComponent<PosthogProviderProps> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[] | undefined>();

  useEffect(() => {
    posthog.onFeatureFlags((flags) => {
      const payload = flags.map((flag) => {
        const flagPayload = posthog.getFeatureFlagPayload(flag);

        return {
          name: flag,
          ...(flagPayload && { payload: flagPayload }),
        };
      });

      setFeatureFlags(payload as unknown as FeatureFlag[]);
    });
  }, []);

  const posthogContext: PosthogContextType = useMemo(
    () => ({ featureFlags: featureFlags ?? [] }),
    [featureFlags]
  );

  return (
    <PosthogContext.Provider value={posthogContext}>
      {children}
    </PosthogContext.Provider>
  );
};

export default PosthogProvider;
