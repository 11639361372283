import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type { FunctionComponent } from 'react';
import { OrgChart } from 'd3-org-chart';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import { ReportContext } from '../../providers/ReportProvider';
import TreeChartControls from '../Report/TreeChartControls';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import * as d3 from 'd3';
import CDTNodeInfo from '../Report/Assortment/CDTNodeInfo';
import CDTLegend from '../Report/Assortment/CDTLegend';
import type { CDTNode } from '../../reducers/AssortmentReducer';
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  drawNodePath,
  getNodeY,
  nodeBodyStyle,
  nodeHeaderStyle,
  salesStyle,
  skusStyle,
  profitStyle,
} from '../../utils/CDTUtils';
import { Button } from '@carbon/react';
import { Number_1, Number_2, Number_3 } from '@carbon/icons-react';
import { VISUAL_CONTAINER_KEY } from '../../constants/values';
import { AppContext } from '../../providers/AppProvider';
import usePosthog from '../../utils/posthog';
import {
  cdtNodeClick,
  treeChartExpandLevelClick,
} from '../../constants/posthog';
import type { Visual } from '../../reducers/ReportReducer';
import { EXPORT_DATA_STATUS, VisualType } from '../../reducers/ReportReducer';
import Fetch from '../Fetch';
import type { DynamicCacheKey } from '../../constants/api';
import type { ServerExportOptions } from '../DownloadOptions/DownloadOptions';
import {
  clickDownload,
  isUpdateMessage,
  serverDataGridExport,
} from '../../utils/reportUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../providers/ModalProvider';
import ExportDataGridNotification from '../Report/ExportDataGridNotification';
import type { GenericWebsocketMessage } from '../../types/websocket';
import type { UpdatePayload } from '../Report/DataVisual';
import useWebsocketListener from '../../hooks/useWebsocketListener';

interface CDTProps {
  visual: Visual;
}

const CDT: FunctionComponent<CDTProps> = ({ visual }) => {
  const CDTId = 100;
  const { user, bannerId, groupId } = useContext(AppContext);
  const { renderSidePanelContent, toggleSidePanelExpand } =
    useContext(SidePanelContext);
  const { cdtData, assortmentData, updateAssortmentData, updateCDTRootName } =
    useContext(AssortmentContext);
  const {
    visualRefs,
    reportConfig,
    visualsData,
    dropdownSelections,
    tabIndex,
  } = useContext(ReportContext);
  const [activeNode, setActiveNode] = useState<CDTNode | null>(null);
  const d3Container = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const { updateModal } = useContext(ModalContext);
  const { fullscreenVisual, treeCharts, updateTreeCharts } =
    useContext(ReportContext);
  const [exporting, setExporting] = useState(false);
  const posthogEvent = usePosthog();
  const chart: OrgChart = treeCharts?.[CDTId];
  const visualDropdownSelection = dropdownSelections?.[tabIndex]?.[visual.id];
  const deselectNode = () => setActiveNode(null);

  useEffect(() => {
    activeNode &&
      renderSidePanelContent(
        <CDTNodeInfo
          key={activeNode.id}
          node={activeNode}
          cdtData={cdtData}
          assortmentData={assortmentData}
          isAuthor={user?.id === reportConfig.user_id}
          updateAssortmentData={updateAssortmentData}
          updateCDTRootName={updateCDTRootName}
          toggleSidePanelExpand={toggleSidePanelExpand}
          deselectNode={deselectNode}
        />,
        null,
        true
      );
  }, [cdtData, activeNode, assortmentData]);

  const drawCDT = () => {
    chart
      ?.data(cdtData)
      .container('.cdt-container')
      .svgHeight(
        fullscreenVisual !== null
          ? window.innerHeight
          : window.innerHeight - 200
      )
      .nodeHeight(() => NODE_HEIGHT)
      .nodeWidth(() => NODE_WIDTH)
      .duration(100)
      .setActiveNodeCentered(false)
      .linkUpdate(function (this: Element) {
        d3.select<d3.BaseType, CDTNode>(this)
          .attr('stroke', '#1b71d5')
          .attr('stroke-width', (d: CDTNode) => Number(d.depth !== 3));
      })
      .onNodeClick(function (this: OrgChart, nodeId: string | number) {
        const node = this.allNodes.find((n: CDTNode) => n.id === nodeId);
        setActiveNode(node);
        posthogEvent(cdtNodeClick, {
          nodeId: node.data.id,
          children: node.children?.map((node) => node.data.id),
        });
      })
      .siblingsMargin(() => 200)
      .compact(false)
      .nodeUpdate(function (this: Element, node: CDTNode) {
        if (node.depth === 3) {
          const d3node = d3.select(this) as unknown as d3.Selection<
            Element,
            CDTNode,
            Element,
            CDTNode
          >;
          d3node
            .select('.node-foreign-object')
            .attr('x', () => node.width - 114)
            .attr('y', () => getNodeY(node));

          d3node
            .select('.node-rect')
            .attr('x', () => node.width - 114)
            .attr('y', () => getNodeY(node));

          const nodePath = d3node.select('path').node()
            ? d3node.select('path')
            : d3node.append('path');

          drawNodePath(
            nodePath as d3.Selection<Element, CDTNode, Element, CDTNode>
          );
        }
      })
      .nodeContent(function (d: CDTNode) {
        const active = activeNode && activeNode.id === d.id;
        return `<div style="background: ${
          active ? '#f0f5f8' : '#fff'
        }; border: ${active ? 2 : 1}px solid #1b71d5; height: 100%; font-size: 16px; border-radius: 8px;">
    <div style="${nodeHeaderStyle}">${d.data.name}</div>
    <div style="${nodeBodyStyle}">
    <div style="${skusStyle}">${d.data.skus}</div>
    <div style="${salesStyle}">${d.data.sales}</div>
    ${
      user?.show_extension_measure
        ? `<div style="${profitStyle}">${d.data.profit}</div>`
        : ''
    }
    </div>
    </div>`;
      })
      .layoutBindings({
        top: {
          ...chart?.getChartState().layoutBindings.top,
          nodeFlexSize: ({
            height,
            width,
            siblingsMargin,
            childrenMargin,
            node,
          }) => {
            return [
              node.depth === 3 ? 0 : width + siblingsMargin,
              height + childrenMargin,
            ];
          },
        },
      })
      .render()
      .expandAll();
  };

  useLayoutEffect(() => {
    if (assortmentData.length) {
      drawCDT();
    }
  }, [assortmentData, d3Container.current, cdtData, activeNode]);

  useEffect(() => {
    updateTreeCharts(CDTId, new OrgChart());
  }, []);

  useEffect(() => {
    if (chart) {
      chart.resetToDefault = () => {
        chart.expandAll();
        chart.fit();
      };

      chart.expandToLevel = (level: number) => {
        const { allNodes } = chart.getChartState();
        allNodes?.forEach((d) => {
          d.data._expanded = d.data.level === level;
        });
        chart.render();
      };
    }
  }, [chart, assortmentData, cdtData]);

  const handleChartExpand = (level: number) => {
    chart.expandToLevel(level);
    posthogEvent(treeChartExpandLevelClick, {
      expandLevel: level,
      reportType: reportConfig.url_route,
    });
  };

  const handleGridServerExport = async ({
    fileName,
    exportedFileId,
    exportCurrentFile,
    includeHiddenColumns,
    isSplitReport,
  }: ServerExportOptions) => {
    const { apiUrl: fileApiUrl } = visual;
    if (fileApiUrl && bannerId && groupId) {
      try {
        setExporting(true);
        const token = await getAccessTokenSilently();
        const dropdownConfig = visualsData[visual.id]?.dropdownConfig?.filter(
          ({ fetch }) => fetch
        );
        const dropdownSelection = isSplitReport ? visualDropdownSelection : '1'; // Key "1" is used for unsplit reports (reportConfig.report_version !== "v2")
        await serverDataGridExport({
          visualApiUrl: fileApiUrl,
          reportName: fileName,
          bannerId,
          groupId,
          token,
          dropdownConfig: dropdownConfig ?? [],
          reportId: reportConfig.run_id,
          visualId: exportedFileId,
          dropdownSelection: exportCurrentFile ? dropdownSelection : null,
          includeHiddenColumns,
        });
      } catch {
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an issue downloading the file. Please try again.',
        });
      } finally {
        setExporting(false);
      }
    }
  };

  return (
    <div
      className={`cdt-container fullscreen-${fullscreenVisual === CDTId}`}
      data-testid="cdt-container"
      ref={(el) => {
        if (visualRefs?.current && el) {
          visualRefs.current[`${VISUAL_CONTAINER_KEY}-${CDTId}`] = el;
        }
      }}
    >
      <ExportDataGridNotification visualId={CDTId} exporting={exporting} />
      <CDTLegend />
      {chart && (
        <TreeChartControls
          treeId={CDTId}
          visualId={visual.id}
          exportUrl={visual.apiUrl}
          treeChart={chart}
          handleGridServerExport={handleGridServerExport}
          extras={[
            <Button
              kind="ghost"
              key="expand_1"
              renderIcon={() => <Number_1 size="18" />}
              hasIconOnly
              iconDescription="Expand to level 1"
              onClick={() => handleChartExpand(1)}
              tooltipPosition="bottom"
            />,
            <Button
              kind="ghost"
              key="expand_2"
              renderIcon={() => <Number_2 size="18" />}
              hasIconOnly
              iconDescription="Expand to level 2"
              onClick={() => handleChartExpand(2)}
              tooltipPosition="bottom"
            />,
            <Button
              kind="ghost"
              key="expand_3"
              iconDescription="Expand to level 3"
              renderIcon={() => <Number_3 size="18" />}
              hasIconOnly
              onClick={() => handleChartExpand(3)}
              tooltipPosition="bottom"
            />,
          ]}
        />
      )}
      <div ref={d3Container} />
    </div>
  );
};

const CDTVisual = () => {
  const { user, groupId } = useContext(AppContext);
  const { reportConfig, updateVisualsData, updateReportConfig } =
    useContext(ReportContext);

  const {
    run_id,
    configuration: { visuals },
    parameters: { template_id: templateId },
  } = reportConfig;

  const reportTemplateIndex = 0;
  const reportTemplateId = `${templateId}_${reportTemplateIndex}`;

  const cdtTreeVisual = visuals[reportTemplateId].find(
    ({ type }) => type === VisualType.CDT_GRID
  );

  const onMessage = useCallback(
    (message: GenericWebsocketMessage | UpdatePayload) => {
      if (!isUpdateMessage(message)) {
        return;
      }
      const { data } = message;
      if (data.run_id !== run_id) {
        return;
      }
      if (data.type === 'export-grid') {
        const { status, url, id } = data;
        updateReportConfig({
          ...reportConfig,
          export_data: {
            ...reportConfig.export_data,
            [id]: { url, status },
          },
        });
        const name = reportConfig.report_name;
        if (status === EXPORT_DATA_STATUS.COMPLETED && user && groupId) {
          clickDownload(url, name, 'xlsx', user.id, groupId, origin);
        }
      }
    },
    [groupId, reportConfig, user?.id]
  );

  useWebsocketListener(onMessage);

  if (!cdtTreeVisual) {
    return null;
  }
  const { id, apiUrl } = cdtTreeVisual;

  return (
    <Fetch
      cacheKey={`${id}-/reports${apiUrl}` as DynamicCacheKey}
      initialData={null}
      loadingMessage="Loading report config..."
      apiUrl={`/reports${apiUrl}`}
      alwaysFetchOnMount={false}
      requestCanBeAborted
      onReceiveData={(response) => {
        if (response) {
          updateVisualsData(id, response);
        }
      }}
    >
      <CDT visual={cdtTreeVisual} />
    </Fetch>
  );
};

export default CDTVisual;
