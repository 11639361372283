import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { Button, Tabs, TabList, Tab, TabPanels, TabPanel } from '@carbon/react';
import '../../styles/components/AIAdmin.scss';
import IconTitle from '../IconTitle';
import AI from '../../assets/icons/Ai';
import type { AIQuestion } from './AIAdmin.types';
import { CACHE_KEY } from '../../constants/api';
import { AppContext } from '../../providers/AppProvider';
import Fetch from '../Fetch';
import { useNavigate } from 'react-router-dom';
import AIQuestions from './AIQuestions';
import { aiAdminTabsLabels, AiQuestionStatus } from './AIAdmin.constants';
import { AIAdminContext } from '../../providers/AIAdminProvider';

const AIAdmin: FunctionComponent = () => {
  const { questions, setAIQuestions } = useContext(AIAdminContext);
  const { bannerId } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="AIAdmin__header">
        <div className="AIAdmin__header--container">
          <IconTitle
            title="AI Manager"
            description="Create, edit and manage OneViu AI Questions"
            icon={<AI size={20} />}
            kind="primary"
          />
        </div>
        <Button
          kind="primary"
          className="has-icon"
          size="md"
          renderIcon={AI}
          onClick={() => navigate('/ai-manager/create')}
        >
          Create AI Question
        </Button>
      </div>
      <Fetch
        apiUrl={`/admin/genai/questions?tdp=${bannerId}`}
        cacheKey={CACHE_KEY.GENAI_QUESTIONS}
        initialData={questions}
        hideChildrenUntilFetched
        loadingMessage="Loading Oneviu AI questions..."
        alwaysFetchOnMount={!questions.length}
        onReceiveData={(data: AIQuestion[]) => {
          if (data) {
            setAIQuestions(data);
          }
        }}
      >
        <Tabs>
          <TabList aria-label="AI Admin">
            <Tab>{aiAdminTabsLabels.published}</Tab>
            <Tab>{aiAdminTabsLabels.unpublished}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel
              className="AIAdmin__tab AIAdmin__tab--published"
              data-testid="AIAdmin-tabPanel-published"
            >
              <div className="AIAdmin__card">
                <AIQuestions
                  questions={questions.filter(
                    ({ question_status }) =>
                      question_status === AiQuestionStatus.Published
                  )}
                  isSortable={true}
                />
              </div>
            </TabPanel>
            <TabPanel
              className="AIAdmin__tab"
              data-testid="AIAdmin-tabPanel-unpublished"
            >
              <div className="AIAdmin__card">
                <AIQuestions
                  questions={questions.filter(
                    ({ question_status }) =>
                      question_status !== AiQuestionStatus.Published
                  )}
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Fetch>
    </>
  );
};

export default AIAdmin;
