import {
  UPDATE_AI_ADMIN_QUESTION_FORM,
  RESET_AI_ADMIN_QUESTION_FORM,
  SET_AI_ADMIN_QUESTION_FORM,
} from '../constants/reducers';
import type { AIQuestionForm } from '../components/AIAdmin/AIAdmin.types';
import {
  AI_INITIAL_PROMPT_VALUE,
  AiQuestionStatus,
} from '../components/AIAdmin/AIAdmin.constants';

type UpdateAIAdminForm<T extends keyof AIQuestionForm = keyof AIQuestionForm> =
  {
    type: typeof UPDATE_AI_ADMIN_QUESTION_FORM;
    key: T;
    value: AIQuestionForm[T];
  };

type ResetAIAdminForm = {
  type: typeof RESET_AI_ADMIN_QUESTION_FORM;
};

type SetAIAdminForm = {
  type: typeof SET_AI_ADMIN_QUESTION_FORM;
  value: AIQuestionForm;
};

export type AIAdminFormAction =
  | UpdateAIAdminForm
  | ResetAIAdminForm
  | SetAIAdminForm;

export const initialAIAdminFormState: AIQuestionForm = {
  is_active: false,
  question: '',
  prompt: AI_INITIAL_PROMPT_VALUE,
  linked_files: [],
  question_status: AiQuestionStatus.Draft,
  max_tokens: 500,
  temperature: 0,
  top_p: 0.999,
};

export const AIAdminFormReducer = (
  state: AIQuestionForm,
  action: AIAdminFormAction
): AIQuestionForm => {
  switch (action.type) {
    case UPDATE_AI_ADMIN_QUESTION_FORM:
      return {
        ...state,
        [action.key]: action.value,
      };
    case RESET_AI_ADMIN_QUESTION_FORM:
      return initialAIAdminFormState;
    case SET_AI_ADMIN_QUESTION_FORM: {
      return action.value;
    }
  }
};
