import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { FormLabel, TextInput, InlineLoading } from '@carbon/react';
import { Edit, Star, StarFilled, Save, TrashCan } from '@carbon/icons-react';
import Tooltip from '../Tooltip';
import usePosthog from '../../utils/posthog';
import {
  reportBuilderHierarchyEditName,
  reportBuilderPinClick,
} from '../../constants/posthog';

interface ParameterProps {
  parameter: ReportParameter;
  showSubmit?: boolean;
  isEditable?: boolean;
  isParameterPinned?: boolean;
  isPinIconHidden?: boolean;
  onEdit: (parameter: ReportParameter) => void;
  onDelete: (parameter: ReportParameter) => void;
  pinGroup?: (parameters: ReportParameter[]) => Promise<void>;
  deleteFavourite?: (name: string) => Promise<void>;
}

const ParameterBlock: FunctionComponent<ParameterProps> = ({
  parameter,
  onEdit,
  onDelete,
  showSubmit,
  isEditable,
  pinGroup,
  deleteFavourite,
  isParameterPinned,
  isPinIconHidden,
}) => {
  const posthogEvent = usePosthog();
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(parameter.name);
  const [isPinned, setIsPinned] = useState(isParameterPinned);
  const [isLoading, setIsLoading] = useState(false);

  const saveChanges = () => {
    posthogEvent(reportBuilderHierarchyEditName, {
      origin: showSubmit ? 'summary' : 'add-group-btn',
      newName: name,
    });
    setEditing(false);
    onEdit({ ...parameter, name });
  };

  const parameterBlockClasses = `parameter-block ${
    !showSubmit && !isEditable ? 'dimensions-label' : ''
  } ${showSubmit ? 'editable-label' : ''}`;

  useEffect(() => {
    setIsPinned(isParameterPinned);
  }, [isParameterPinned]);

  const handlePin = async () => {
    posthogEvent(reportBuilderPinClick, {
      origin: 'pin-icon',
    });
    setIsLoading(true);
    if (isPinned) {
      await deleteFavourite?.(parameter.name);
    } else {
      await pinGroup?.([parameter]);
    }
    setIsLoading(false);
    setIsPinned(!isPinned);
  };

  return (
    <div className={parameterBlockClasses} data-testid="parameter-block">
      {isEditable ? (
        <FormLabel>{name}</FormLabel>
      ) : (
        <TextInput
          id={parameter.name + 'text-input'}
          className={`${!showSubmit ? 'parameter-label ' : 'dimensions-label'}`}
          data-testid="parameter-label"
          labelText="Parameter name"
          hideLabel
          readOnly={!editing}
          value={name}
          onClick={() => setEditing(true)}
          onKeyDown={({ code }) => {
            if (code === 'Enter') {
              saveChanges();
            }
          }}
          onChange={({ target }) => setName(target.value)}
        />
      )}

      {!isEditable && (
        <div className="parameter-actions">
          {editing ? (
            <Tooltip label="Save changes" useButtonWrapper>
              <Save
                data-testid="save-parameter-btn"
                onClick={() => saveChanges()}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Edit selection name" useButtonWrapper>
              <Edit
                data-testid="edit-parameter-btn"
                onClick={() => setEditing(!editing)}
              />
            </Tooltip>
          )}
          {showSubmit && !isLoading && !isPinIconHidden && (
            <Tooltip
              label={isPinned ? 'Remove from Favourites' : 'Add to Favourites'}
              useButtonWrapper
            >
              {isPinned ? (
                <StarFilled
                  data-testid="pin-filled-parameter-btn"
                  onClick={handlePin}
                  className="pin-filled"
                />
              ) : (
                <Star data-testid="pin-parameter-btn" onClick={handlePin} />
              )}
            </Tooltip>
          )}

          {isLoading && <InlineLoading />}
          <Tooltip label="Delete" useButtonWrapper>
            <TrashCan
              data-testid="delete-parameter-btn"
              onClick={() => onDelete(parameter)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ParameterBlock;
