/* eslint-disable indent */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import {
  Button,
  InlineLoading,
  InlineNotification,
  ToastNotification,
} from '@carbon/react';
import { Compare, Report } from '@carbon/icons-react';
import NPDReducer, {
  initialNPDState,
  NPDProductSelection,
  type NPDGridRowDataType,
  type NPDState,
} from '../reducers/NPDReducer';
import {
  ADD_TO_BENCHMARK_UNSELECTED_ROWS,
  RESET_NPD_ROW_SELECTION,
  UPDATE_NPD_FINDER_SAVE_STATE,
  UPDATE_NPD_ROW_FILE_DATA,
  UPDATE_NPD_ROWS,
} from '../constants/reducers';
import { ReportContext } from './ReportProvider';
import {
  convertNPDRowsIntoReportParameters,
  findVisual,
  getNPDFinderPayload,
  getNPDProductCards,
} from '../utils/reportUtils';
import {
  VisualType,
  type FileData,
  type VisualData,
} from '../reducers/ReportReducer';
import ReportMetricsCard from '../components/Cards/ReportMetricsCard';
import '../styles/components/NPDFinder.scss';
import { useFeatureFlagEnabled } from '../context/posthog';
import apiRequest from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from './ModalProvider';
import { getReportPath } from '../utils/CDTUtils';
import type { ResponsePayload } from '../components/Fetch';
import SharedDropdowns from '../components/Report/Dropdowns/SharedDropdowns';
import { AppContext } from './AppProvider';
import { useNavigate } from 'react-router-dom';

export interface NPDContextType extends NPDState {
  isResetButtonDisabled: boolean;
  isNPDFinderFeaturesEnabled: boolean;
  resetGridSelection: () => void;
  updateNPDRowFileData: (
    fileKey: string,
    dropdownKey: string,
    rows: NPDGridRowDataType[]
  ) => void;
  updateNPDRows: (
    fileKey: string,
    rowData: NonNullable<VisualData<NPDGridRowDataType>['rows']>
  ) => void;
  updateNPDFinderSaveState: ({
    isSaving,
    isSaveComplete,
  }: {
    isSaving: boolean;
    isSaveComplete: boolean;
  }) => void;
}

const AUTOSAVE_DELAY = 20000;

export const NPDContext = createContext<NPDContextType>({} as NPDContextType);

interface NPDProviderProps {
  children: ReactNode;
  reportTemplateId: string;
}

const NPDProvider: FunctionComponent<NPDProviderProps> = ({
  children,
  reportTemplateId,
}) => {
  const [state, dispatch] = useReducer(NPDReducer, initialNPDState);
  const { getAccessTokenSilently } = useAuth0();
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [isValidationNotificationVisible, setIsValidationNotificationVisible] =
    useState(false);
  const [isValidationInformationVisible, setIsValidationInformationVisible] =
    useState(true);
  const { bannerId, groupId } = useContext(AppContext);
  const {
    visualsData,
    dropdownSelections,
    tabIndex,
    reportConfig: {
      configuration: { visuals, switchers },
    },
  } = useContext(ReportContext);
  const { updateModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const NPDGridVisualConfig = findVisual(
    visuals[reportTemplateId],
    (vis) => vis.type === VisualType.SELECTION_GRID
  );
  const { id: NPDGridId } = NPDGridVisualConfig ?? {};
  const NPDGridVisualData =
    typeof NPDGridId === 'number'
      ? (visualsData[NPDGridId] as VisualData<NPDGridRowDataType>)
      : undefined;
  const { files, ...sharedVisualData } = NPDGridVisualData || {};
  const visualDropdownSelection =
    typeof NPDGridId === 'number' && dropdownSelections?.[tabIndex]
      ? dropdownSelections[tabIndex][NPDGridId]
      : undefined;
  const currentVisualData = visualDropdownSelection
    ? ({
        ...sharedVisualData,
        ...files?.[visualDropdownSelection],
      } as FileData<NPDGridRowDataType>)
    : undefined;
  const dropdownSelection = currentVisualData?.fileDropdownSelections;
  const isNPDFinderFeaturesEnabled = useFeatureFlagEnabled('npd-finder');
  const { isSaveComplete, isSaving, files: NPDFiles } = state;
  const { rows: currentFileRows } =
    (visualDropdownSelection ? NPDFiles[visualDropdownSelection] : undefined) ??
    {};

  const updateNPDRowFileData = (
    fileKey: string,
    dropdownKey: string,
    rows: NPDGridRowDataType[]
  ) => {
    dispatch({ type: UPDATE_NPD_ROW_FILE_DATA, rows, dropdownKey, fileKey });
  };

  const updateNPDRows = (
    fileKey: string,
    rowData: NonNullable<VisualData<NPDGridRowDataType>['rows']>
  ) => {
    dispatch({ type: UPDATE_NPD_ROWS, rowData, fileKey });
  };

  const updateNPDFinderSaveState = ({
    isSaving,
    isSaveComplete,
  }: {
    isSaving: boolean;
    isSaveComplete: boolean;
  }) => {
    dispatch({ type: UPDATE_NPD_FINDER_SAVE_STATE, isSaving, isSaveComplete });
  };

  const saveNPDFinderData = async (
    rows: Record<string, NPDGridRowDataType[]>
  ): Promise<ResponsePayload<{ message: string }> | undefined> => {
    try {
      updateNPDFinderSaveState({ isSaving: true, isSaveComplete: false });
      const token = await getAccessTokenSilently();
      const rowsPayload = getNPDFinderPayload(rows);
      if (NPDGridVisualConfig?.apiUrl) {
        const savePayload = {
          path: `${getReportPath(
            NPDGridVisualConfig.apiUrl,
            true
          )}/${visualDropdownSelection}`,
          payload: {
            rows: rowsPayload,
          },
        };
        const result = await apiRequest<ResponsePayload<{ message: string }>>(
          '/reports/file-updates',
          'POST',
          token,
          savePayload
        );
        updateNPDFinderSaveState({ isSaving: false, isSaveComplete: true });
        return result;
      }
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error saving the data. Please try again. If the issue persists, please contact the helpdesk.',
      });
      updateNPDFinderSaveState({ isSaving: false, isSaveComplete: false });
    }
  };

  useEffect(() => {
    if (
      currentVisualData?.rows &&
      visualDropdownSelection &&
      !currentFileRows
    ) {
      updateNPDRows(visualDropdownSelection, currentVisualData.rows);
    }
  }, [currentVisualData?.rows, visualDropdownSelection, currentFileRows]);

  useEffect(() => {
    if (isSaveComplete || !isNPDFinderFeaturesEnabled || !currentFileRows) {
      return;
    }
    const timer = setTimeout(
      () => saveNPDFinderData(currentFileRows),
      AUTOSAVE_DELAY
    );
    return () => {
      clearTimeout(timer);
    };
  }, [currentFileRows, isNPDFinderFeaturesEnabled, isSaveComplete]);

  const cardsData: Card[] = useMemo(() => {
    if (!dropdownSelection || !currentFileRows) {
      return [];
    }
    const cardData = getNPDProductCards(currentFileRows[dropdownSelection]);

    if (!cardData) {
      return [];
    }

    return [
      {
        title: 'Focus Products',
        tooltip: 'Select at least 1 SKU',
        index: 'neutral',
        data: {
          value: cardData.focusProducts,
          format: 'integer',
        },
      },
      {
        title: 'Benchmark Products',
        tooltip: 'Select at least 6 SKUs',
        index: 'neutral',
        data: {
          value: cardData.benchmarkProducts,
          format: 'integer',
        },
      },
    ];
  }, [currentFileRows, dropdownSelection]);

  const isAddToBenchmarkButtonDisabled = useMemo(() => {
    if (!dropdownSelection) {
      return true;
    }
    return !currentFileRows?.[dropdownSelection]?.some(
      ({ SELECTION }) => SELECTION.type === NPDProductSelection.UNSELECTED
    );
  }, [currentFileRows, dropdownSelection]);

  const handleAddToBenchmarkClick = () => {
    if (!dropdownSelection || !visualDropdownSelection) {
      return;
    }
    dispatch({
      type: ADD_TO_BENCHMARK_UNSELECTED_ROWS,
      fileKey: visualDropdownSelection,
      dropdownKey: dropdownSelection,
    });
    setIsToastVisible(true);
    setTimeout(() => setIsToastVisible(false), 10000);
  };

  const handleRunNPDReportClick = async () => {
    if (!dropdownSelection || !currentFileRows || !bannerId) {
      return;
    }
    const result = await saveNPDFinderData(currentFileRows);
    if (result) {
      const focusRows = currentFileRows[dropdownSelection].filter(
        ({ SELECTION }) => SELECTION.type === NPDProductSelection.FOCUS
      );
      const benchmarkRows = currentFileRows[dropdownSelection].filter(
        ({ SELECTION }) => SELECTION.type === NPDProductSelection.BENCHMARK
      );
      if (focusRows.length >= 1 && benchmarkRows.length >= 6) {
        setIsValidationNotificationVisible(false);
        const reportParameters = convertNPDRowsIntoReportParameters(
          focusRows,
          benchmarkRows,
          bannerId
        );
        navigate('/modules/NPD/npd-prfmc', {
          state: {
            initialParameters: reportParameters,
            bannerId,
            groupId,
          },
        });
      } else {
        setIsValidationNotificationVisible(true);
      }
    }
  };

  const isResetButtonDisabled = useMemo(() => {
    if (!dropdownSelection) {
      return true;
    }
    return !!currentFileRows?.[dropdownSelection]?.every(
      ({ SELECTION }) => SELECTION.type === NPDProductSelection.UNSELECTED
    );
  }, [currentFileRows, dropdownSelection]);

  const resetGridSelection = () => {
    if (!dropdownSelection || !visualDropdownSelection) {
      return;
    }
    dispatch({
      type: RESET_NPD_ROW_SELECTION,
      dropdownKey: dropdownSelection,
      fileKey: visualDropdownSelection,
    });
  };

  const NPDContextValue: NPDContextType = useMemo(
    () => ({
      files: state.files,
      isSaving,
      isSaveComplete,
      isResetButtonDisabled: isResetButtonDisabled || isSaving,
      isNPDFinderFeaturesEnabled,
      resetGridSelection,
      updateNPDRowFileData,
      updateNPDRows,
      updateNPDFinderSaveState,
    }),
    [
      isNPDFinderFeaturesEnabled,
      isResetButtonDisabled,
      isSaveComplete,
      isSaving,
      state.files,
    ]
  );

  return (
    <NPDContext.Provider value={NPDContextValue}>
      <SharedDropdowns
        tab={tabIndex}
        visualIds={switchers?.[reportTemplateId][tabIndex]?.visualContent}
        readOnly
      />
      <div className="NPDProvider">
        {isNPDFinderFeaturesEnabled && cardsData.length > 0 ? (
          <>
            <div className="NPDProvider__notifications">
              {isValidationNotificationVisible && !state.isSaving && (
                <InlineNotification
                  title="Minimum Requirements not met"
                  subtitle="Please select min 1 Focus and 6 Benchmark SKUs"
                  className="NPDProvider__notifications-error"
                  kind="error"
                  lowContrast
                  onCloseButtonClick={() =>
                    setIsValidationNotificationVisible(false)
                  }
                />
              )}
              {!isValidationNotificationVisible &&
                isValidationInformationVisible && (
                  <InlineNotification
                    kind="info"
                    className="NPDProvider__notifications-info"
                    title="Start by selecting at least 1 Focus and 6 Benchmark SKUs for your focus and benchmark groups"
                    lowContrast
                    onCloseButtonClick={() =>
                      setIsValidationInformationVisible(false)
                    }
                  />
                )}
            </div>
            <div className="NPDProvider__actions">
              <div className="NPDProvider__actions-cards">
                <ReportMetricsCard
                  items={cardsData}
                  hideIndicator
                  hasPlaceholderItems={false}
                  borderColour="#F25829"
                />
              </div>
              <div className="NPDProvider__actions-buttons">
                {isSaving && (
                  <InlineLoading description="Saving your changes" />
                )}
                <Button
                  kind="secondary"
                  className="gap--small"
                  size="sm"
                  disabled={isAddToBenchmarkButtonDisabled || isSaving}
                  onClick={handleAddToBenchmarkClick}
                >
                  Add all unselected to Benchmark <Compare />
                </Button>
                <Button
                  className="gap--small"
                  size="sm"
                  onClick={handleRunNPDReportClick}
                  disabled={isSaving}
                >
                  Run NPD Performance Report <Report />
                </Button>
                {isToastVisible && (
                  <ToastNotification
                    className="NPDProvider__toast"
                    title="All unselected rows have been added to Benchmark"
                    size="sm"
                    kind="info"
                    lowContrast
                    onCloseButtonClick={() => setIsToastVisible(false)}
                  />
                )}
              </div>
            </div>
          </>
        ) : null}
        {children}
      </div>
    </NPDContext.Provider>
  );
};

export const NPDWrapper = (
  children: JSX.Element[],
  reportTemplateId: string
) => <NPDProvider reportTemplateId={reportTemplateId}>{children}</NPDProvider>;

export default NPDProvider;
