/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useRef } from 'react';
import { ReportContext } from '../../providers/ReportProvider';
import {
  Calendar,
  ChevronDown,
  ChevronUp,
  FitToScreen,
  Maximize,
  Minimize,
  ZoomIn,
  ZoomOut,
  Reset,
} from '@carbon/icons-react';
import { Button } from '@carbon/react';
import ExportOverflow from '../Charts/ExportOverflow';
import {
  ALL_VISUALS_KEY,
  SHARED_DROPDOWN_KEY,
  VISUAL_CONTAINER_KEY,
} from '../../constants/values';
import usePosthog from '../../utils/posthog';
import {
  treeChartCollapseTreeClick,
  treeChartExpandFullScreenClick,
  treeChartExpandTreeClick,
  treeChartFitToScreenClick,
  treeChartReturnToDefaultClick,
  treeChartZoomClick,
} from '../../constants/posthog';
import { formatDateRange } from '../../utils/DateUtils';
import CardHeader from '../Cards/CardHeader';
import type { TimePeriodData } from '../../reducers/ReportReducer';
import type { GlobalSelection } from './ReportContent';
import type { ServerExportOptions } from '../DownloadOptions/DownloadOptions';
import { getExportFileName } from '../../utils/chartUtils';

interface TreeChartControlsProps {
  treeId: number;
  visualId?: number;
  exportUrl?: string;
  treeChart: TreeChart;
  extras?: JSX.Element[];
  visualHeader?: VisualHeader;
  tab?: number | string;
  exportAllCharts?: boolean;
  timePeriods?: TimePeriodData;
  globalSelections?: GlobalSelection[];
  handleGridServerExport?: (options: ServerExportOptions) => Promise<void>;
}

const TreeChartControls: FunctionComponent<TreeChartControlsProps> = ({
  treeId,
  visualId,
  treeChart,
  extras,
  visualHeader,
  tab,
  exportAllCharts,
  timePeriods,
  globalSelections,
  exportUrl,
  handleGridServerExport,
}) => {
  const {
    visualRefs,
    fullscreenVisual,
    toggleVisualFullscreen,
    setVisualControlsRef,
    reportConfig: { report_name, url_route },
  } = useContext(ReportContext);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const posthogEvent = usePosthog();
  const postTimePeriod = timePeriods?.['2'];
  const exportedFileName = getExportFileName({
    reportName: report_name,
    visualHeaderTitle: visualHeader?.title,
  });

  const toggleExpand = (expand: boolean) => {
    if (treeChart) {
      if (expand) {
        treeChart.expandAll?.();
        treeChart.fit?.();
      } else {
        treeChart.collapseAll?.();
        treeChart.fit?.();
      }
    }
  };

  const zoom = (direction: 'in' | 'out') => {
    if (treeChart) {
      if (direction === 'in') {
        treeChart.zoomIn?.();
      } else {
        treeChart.zoomOut?.();
      }
    }
  };

  const fitChart = () => {
    if (treeChart) {
      treeChart.fit?.();
    }
  };

  const resetChart = () => {
    if (treeChart) {
      treeChart.resetToDefault?.();
    }
  };

  const handleExpand = () =>
    toggleVisualFullscreen(fullscreenVisual === treeId ? null : treeId);

  const handleZoom = (direction: 'in' | 'out') => {
    zoom(direction);
    posthogEvent(treeChartZoomClick, {
      zoom: direction,
      reportType: url_route,
    });
  };

  useEffect(() => {
    if (controlsRef) {
      setVisualControlsRef(treeId, controlsRef);
    }
  }, []);

  return (
    <div className="kpi-tree-controls">
      <div ref={controlsRef}>
        {visualHeader?.title && <CardHeader {...visualHeader} />}
      </div>
      {postTimePeriod && (
        <div className="TreeChartControls__date-periods-container">
          <div className="TreeChartControls__date-periods">
            <Calendar />
            <div className="TreeChartControls__date-periods-label">
              <span>Period:</span>{' '}
              {formatDateRange({
                startDate: postTimePeriod.startDate,
                endDate: postTimePeriod.endDate,
                formatToken: 'd LLL yyyy',
              })}
            </div>
          </div>
        </div>
      )}
      <div>
        {extras}
        <Button
          kind="ghost"
          renderIcon={ZoomIn}
          iconDescription="Zoom in"
          onClick={() => handleZoom('in')}
          hasIconOnly={true}
          tooltipPosition="top"
          size="md"
        />
        <Button
          kind="ghost"
          renderIcon={ZoomOut}
          iconDescription="Zoom out"
          onClick={() => handleZoom('out')}
          hasIconOnly={true}
          tooltipPosition="top"
          size="md"
        />
        <Button
          kind="ghost"
          iconDescription="Expand Tree"
          renderIcon={ChevronDown}
          onClick={() => {
            toggleExpand(true);
            posthogEvent(treeChartExpandTreeClick, {
              reportType: url_route,
            });
          }}
          hasIconOnly={true}
          tooltipPosition="top"
          size="md"
        />
        <Button
          kind="ghost"
          renderIcon={ChevronUp}
          iconDescription="Collapse Tree"
          onClick={() => {
            toggleExpand(false);
            posthogEvent(treeChartCollapseTreeClick, {
              reportType: url_route,
            });
          }}
          hasIconOnly={true}
          tooltipPosition="top"
          size="md"
        />
        <Button
          kind="ghost"
          renderIcon={FitToScreen}
          iconDescription="Fit to Screen"
          onClick={() => {
            fitChart();
            posthogEvent(treeChartFitToScreenClick, {
              reportType: url_route,
            });
          }}
          hasIconOnly={true}
          tooltipPosition="top"
          size="md"
        />
        <Button
          kind="ghost"
          renderIcon={Reset}
          iconDescription="Return to Default View"
          onClick={() => {
            resetChart();
            posthogEvent(treeChartReturnToDefaultClick, {
              reportType: url_route,
            });
          }}
          hasIconOnly={true}
          tooltipPosition="top"
          size="md"
        />
        <ExportOverflow
          id={visualId ?? treeId}
          elements={
            visualRefs?.current
              ? [
                  visualRefs.current[`${SHARED_DROPDOWN_KEY}-${tab}`],
                  exportAllCharts
                    ? visualRefs.current[`${ALL_VISUALS_KEY}_${tab}`]
                    : visualRefs.current[`${VISUAL_CONTAINER_KEY}-${treeId}`],
                ]
              : null
          }
          reportName={exportedFileName}
          alignTooltip="top"
          globalSelections={globalSelections}
          exportUrl={exportUrl}
          handleGridServerExport={handleGridServerExport}
        />
        <Button
          kind="ghost"
          renderIcon={fullscreenVisual !== null ? Minimize : Maximize}
          iconDescription={
            fullscreenVisual !== null
              ? 'Collapse Full Screen'
              : 'Expand Full Screen'
          }
          onClick={() => {
            handleExpand();
            posthogEvent(treeChartExpandFullScreenClick, {
              reportType: url_route,
            });
          }}
          hasIconOnly={true}
          tooltipPosition={fullscreenVisual !== null ? 'left' : 'top'}
          size="md"
        />
      </div>
    </div>
  );
};

export default TreeChartControls;
