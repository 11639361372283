import type { FunctionComponent, ReactNode } from 'react';
import { Button, InlineNotification } from '@carbon/react';
import type { CarbonIconType } from '@carbon/icons-react';
import '../../styles/components/NoDataPlaceholder.scss';

interface NoDataPlaceholderProps {
  title: string;
  description?: {
    info: ReactNode;
    list?: ReactNode[];
  };
  icon: CarbonIconType;
  notificationText?: string;
  buttonLabel?: string;
  buttonIcon?: CarbonIconType;
  onClick?: () => void;
  extras?: JSX.Element;
  buttonSize?: 'sm' | 'md' | 'lg';
}

const NoDataPlaceholder: FunctionComponent<NoDataPlaceholderProps> = ({
  title,
  description,
  icon: Icon,
  notificationText,
  buttonLabel,
  buttonIcon: ButtonIcon,
  onClick,
  extras,
  buttonSize,
}) => {
  return (
    <div className="NoDataPlaceholder" data-testid="no-data-placeholder">
      <Icon size={24} />
      <h4 className="NoDataPlaceholder__title">{title}</h4>
      <div className="NoDataPlaceholder__description">
        <div>{description?.info}</div>
        {description?.list && (
          <ul>
            {description.list.map((item) =>
              item ? <li key={item.toString()}>{item}</li> : null
            )}
          </ul>
        )}
      </div>
      {notificationText && (
        <InlineNotification
          kind="info"
          lowContrast
          hideCloseButton
          title={notificationText}
        />
      )}
      {buttonLabel && (
        <Button onClick={onClick} size={buttonSize}>
          {buttonLabel} {ButtonIcon && <ButtonIcon />}
        </Button>
      )}
      {extras && <div>{extras}</div>}
    </div>
  );
};

export default NoDataPlaceholder;
