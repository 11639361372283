import type { CarbonIconType } from '@carbon/icons-react';
import { Download } from '@carbon/icons-react';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import {
  clickDownload,
  getExportedGridId,
  handleExport,
} from '../../utils/reportUtils';
import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import Tooltip from '../Tooltip';
import { AppContext } from '../../providers/AppProvider';
import { ReportContext } from '../../providers/ReportProvider';
import type { ServerExportOptions } from '../DownloadOptions/DownloadOptions';
import usePosthog from '../../utils/posthog';
import type { GlobalSelection } from '../Report/ReportContent';
import { chartExportXLSX } from '../../constants/posthog';

export type ExportTypes = 'png' | 'svg' | 'xlsx';

export type ExportOverflowConfig = {
  [key in ExportTypes]?: {
    onClick?: () => void;
    hide?: boolean;
  };
};

interface ExportOverflowProps {
  className?: string;
  icon?: CarbonIconType;
  elements: HTMLDivElement[] | null;
  reportName: string;
  alignTooltip?: 'top' | 'left' | 'bottom' | 'right';
  size?: 'sm' | 'md' | 'lg';
  tooltipText?: string;
  disabled?: boolean;
  id?: number;
  globalSelections?: GlobalSelection[];
  exportUrl?: string;
  optionsConfig?: ExportOverflowConfig | null;
  handleGridServerExport?: (options: ServerExportOptions) => Promise<void>;
}

const ExportOverflow: FunctionComponent<ExportOverflowProps> = ({
  className,
  icon,
  elements,
  reportName,
  alignTooltip = 'bottom',
  size = 'md',
  tooltipText = 'Download chart',
  disabled,
  id,
  globalSelections,
  exportUrl,
  optionsConfig,
  handleGridServerExport,
}) => {
  const { user, groupId } = useContext(AppContext);
  const {
    reportConfig: { url_route, export_data },
  } = useContext(ReportContext);
  const posthogEvent = usePosthog();

  const serverGridExport = () => {
    if (typeof id !== 'number') {
      return;
    }
    const exportedFileId = getExportedGridId({
      id,
      globalSelections,
      optionId: 0,
    });
    const fileName = reportName.replaceAll(/[:\\/?*[\]]/gm, '_');
    const exportData = export_data?.[exportedFileId];
    if (exportData?.url && user && groupId) {
      clickDownload(exportData.url, fileName, 'xlsx', user.id, groupId, origin);
    } else {
      if (handleGridServerExport) {
        handleGridServerExport({
          fileName,
          exportedFileId,
          exportCurrentFile: false,
          includeHiddenColumns: true,
          isSplitReport: true,
          isChartExport: true,
        });
        posthogEvent(chartExportXLSX, {
          reportType: url_route,
          origin,
        });
      }
    }
  };

  if (!elements) {
    return null;
  }

  const handleImageExport = (type: Exclude<ExportTypes, 'xlsx'>) => {
    const { onClick } = optionsConfig?.[type] ?? {};
    if (onClick) {
      onClick();
      return;
    }
    if (user && groupId) {
      handleExport(elements, reportName, type, user.id, groupId, url_route);
    }
  };

  const overflowMenuItems = [
    {
      itemText: 'As .png',
      onClick: () => handleImageExport('png'),
      hide: !!optionsConfig?.['png']?.hide,
    },
    {
      itemText: 'As .svg',
      onClick: () => handleImageExport('svg'),
      hide: !!optionsConfig?.['svg']?.hide,
    },
    {
      itemText: 'As .xlsx',
      onClick: serverGridExport,
      hide:
        !exportUrl ||
        !handleGridServerExport ||
        !!optionsConfig?.['xlsx']?.hide,
    },
  ].filter(({ hide }) => !hide);

  return (
    <Tooltip label={tooltipText} align={alignTooltip}>
      <OverflowMenu
        ariaLabel={tooltipText}
        size={size}
        className={className ? className : undefined}
        renderIcon={icon ? icon : Download}
        data-testid="download-chart"
        iconDescription={tooltipText}
        disabled={disabled}
        flipped
        data-floating-menu-container
        focusTrap={false}
      >
        {overflowMenuItems.map(({ itemText, onClick }) => (
          <OverflowMenuItem
            key={itemText}
            itemText={itemText}
            onClick={onClick}
          />
        ))}
      </OverflowMenu>
    </Tooltip>
  );
};

export default ExportOverflow;
