import { createContext, useMemo, useReducer } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import type { AIQuestion } from '../components/AIAdmin/AIAdmin.types';
import {
  aiAdminInitialState,
  aiAdminReducer,
} from '../reducers/AIAdminReducer';
import {
  REMOVE_AI_ADMIN_QUESTION,
  UPDATE_AI_ADMIN_QUESTIONS,
} from '../constants/reducers';

interface AIAdminProviderProps {
  children: ReactNode;
}

export const AIAdminContext = createContext<AIAdminContextType>(
  {} as AIAdminContextType
);

export interface AIAdminContextType {
  questions: AIQuestion[];
  setAIQuestions: (questions: AIQuestion[]) => void;
  removeAIQuestion: (id: string) => void;
}

const AIAdminProvider: FunctionComponent<AIAdminProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(aiAdminReducer, aiAdminInitialState);

  const setAIQuestions = (questions: AIQuestion[]): void => {
    dispatch({ type: UPDATE_AI_ADMIN_QUESTIONS, questions });
  };

  const removeAIQuestion = (questionId: string): void => {
    dispatch({ type: REMOVE_AI_ADMIN_QUESTION, questionId });
  };

  const context: AIAdminContextType = useMemo(
    () => ({
      questions: state,
      setAIQuestions,
      removeAIQuestion,
    }),
    [state]
  );

  return (
    <AIAdminContext.Provider value={context}>
      {children}
    </AIAdminContext.Provider>
  );
};

export default AIAdminProvider;
