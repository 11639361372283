import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import {
  Checkmark,
  Close,
  Copy,
  ThumbsUp,
  ThumbsUpFilled,
  ThumbsDown,
  ThumbsDownFilled,
} from '@carbon/icons-react';
import Fetch from '../../Fetch';
import { CACHE_KEY } from '../../../constants/api';
import type {
  GenAIAnswerActionsProps,
  GenAIFeedbackOption,
} from '../GenAIPanel.types';
import usePosthog from '../../../utils/posthog';
import {
  aiAnswerCopyClick,
  aiAnswerDislikeClick,
  aiAnswerDislikeOptionClick,
  aiAnswerLikeClick,
  aiAnswerDislikeClosedWithoutOption,
} from '../../../constants/posthog';

const BUTTON_ACTIVE_DURATION = 2000;

const GenAIAnswerActions: FunctionComponent<GenAIAnswerActionsProps> = ({
  message,
  question,
}) => {
  const [isLikeActive, setIsLikeActive] = useState(false);
  const [isDislikeActive, setIsDislikeActive] = useState(false);
  const [isDislikeOptionsVisible, setIsDislikeOptionsVisible] = useState(false);
  const [feedbackItems, setFeedbackItems] = useState<string[]>([]);
  const [dislikeReason, setDislikeReason] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);
  const copyTimeoutRef = useRef<number>();
  const dislikeSubmitTimeoutRef = useRef<number>();
  
  const posthogEvent = usePosthog();

  const handleCopy = (): void => {
    clearTimeout(copyTimeoutRef.current);
    navigator.clipboard.writeText(message);
    setIsCopied(true);
    posthogEvent(aiAnswerCopyClick);
    copyTimeoutRef.current = window.setTimeout(
      () => setIsCopied(false),
      BUTTON_ACTIVE_DURATION
    );
  };

  const handleLikeClick = (): void => {
    setIsLikeActive(true);
    setIsDislikeActive(false);
    setIsDislikeOptionsVisible(false);

    posthogEvent(aiAnswerLikeClick, {
      questionText: question,
    });
  };

  const handleDislikeClick = (): void => {
    setIsDislikeActive(true);
    setIsLikeActive(false);
    setIsDislikeOptionsVisible(true);

    posthogEvent(aiAnswerDislikeClick, {
      questionText: question,
    });
  };

  const handleDislikeOptionClick = (option: string): void => {
    setDislikeReason(option);

    posthogEvent(aiAnswerDislikeOptionClick, {
      questionText: question,
      dislikeOption: option,
    });

    dislikeSubmitTimeoutRef.current = window.setTimeout(
      () => setIsDislikeOptionsVisible(false),
      BUTTON_ACTIVE_DURATION
    );
  };

  const handleDislikeOptionsClose = (): void => {
    setIsDislikeOptionsVisible(false);
    posthogEvent(aiAnswerDislikeClosedWithoutOption, {
      questionText: question,
    });
  };

  return (
    <div className="GenAIPanel__actions">
      <div className="GenAIPanel__actions--container">
        <button
          type="button"
          aria-label="Like"
          title="Like"
          className={`GenAIPanel__actions--button ${isLikeActive ? 'active' : ''}`}
          onClick={handleLikeClick}
        >
          {isLikeActive ? <ThumbsUpFilled /> : <ThumbsUp />}
        </button>
        <button
          type="button"
          aria-label="Dislike"
          title="Dislike"
          className={`GenAIPanel__actions--button ${isDislikeActive ? 'active' : ''}`}
          onClick={handleDislikeClick}
        >
          {isDislikeActive ? <ThumbsDownFilled /> : <ThumbsDown />}
        </button>
        <button
          type="button"
          aria-label="Copy"
          title={isCopied ? 'Copied!' : 'Copy'}
          className={`GenAIPanel__actions--button ${isCopied ? 'active' : ''}`}
          onClick={handleCopy}
        >
          {isCopied ? <Checkmark /> : <Copy />}
        </button>
      </div>
      {isDislikeOptionsVisible && (
        <div className="GenAIPanel__actions--options">
          <Fetch
            initialData={feedbackItems}
            alwaysFetchOnMount={
              !feedbackItems?.length || !CACHE_KEY.GENAI_FEEDBACK_ITEMS
            }
            onReceiveData={(data: GenAIFeedbackOption) => {
              if (data) {
                setFeedbackItems(data.options);
              }
            }}
            apiUrl="/genai/questions/dislike-options"
            cacheKey={CACHE_KEY.GENAI_FEEDBACK_ITEMS}
            loadingMessage="Loading OneViu AI feedback options..."
          >
            <div className="GenAIPanel__actions--options__header">
              <p className="GenAIPanel__actions--options__title">
                <ThumbsDownFilled /> Can you provide some feedback please:
              </p>
              {!dislikeReason && (
                <button
                  aria-label="Close dislike options"
                  className="GenAIPanel__actions--options__close"
                  onClick={handleDislikeOptionsClose}
                >
                  <Close />
                </button>
              )}
            </div>
            {dislikeReason ? (
              <>
                <div className="GenAIPanel__message">{dislikeReason}</div>
                <p className="GenAIPanel__actions--options__text">
                  Thank you for the feedback
                </p>
              </>
            ) : (
              feedbackItems?.length > 0 && (
                <div className="flex direction-column align-start gap--medium">
                  {feedbackItems.map((feedbackItem) => (
                    <button
                      key={feedbackItem}
                      className="GenAIPanel__button"
                      onClick={() => handleDislikeOptionClick(feedbackItem)}
                    >
                      {feedbackItem}
                    </button>
                  ))}
                </div>
              )
            )}
          </Fetch>
        </div>
      )}
    </div>
  );
};

export default GenAIAnswerActions;
