/* eslint-disable indent */
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Column,
  Dropdown,
  Grid,
  InlineLoading,
  OverflowMenu,
  OverflowMenuItem,
  InlineNotification,
} from '@carbon/react';
import {
  Document,
  ContentView,
  Logout,
  Information,
  SecurityServices,
} from '@carbon/icons-react';
import { LocalStorageCache, useAuth0 } from '@auth0/auth0-react';
import type { WrappedCacheEntry } from '@auth0/auth0-spa-js/dist/typings/cache/shared';
import jwt_decode from 'jwt-decode';
import { DateTime, Duration } from 'luxon';
import posthog from 'posthog-js';
import { AppContext } from '../providers/AppProvider';
import Sidebar from './Sidebar';
import { AUTH0_SCOPE, CACHE_KEY_PREFIX } from '../constants/auth0';
import type { USER_TYPE } from '../constants/metadata';
import { PAGE_TITLE, APP_SECTIONS, TERMS_STATUS } from '../constants/metadata';
import '../styles/layout/layout.scss';
import {
  BROADCAST_CHANNEL_KEY,
  IDLE_TIME_LIMIT_IN_MS,
} from '../constants/values';
import { ModalContext } from '../providers/ModalProvider';
import { useFeatureFlagEnabled } from '../context/posthog';
import Maintenance from './Maintenance/Maintenance';
import {
  MAINTENANCE_HEADER,
  MAINTENANCE_SUBHEADER,
} from '../constants/messages';
import {
  getAcceptanceStatus,
  getBannersAndUserGroups,
  getUserOptionalTerms,
  getInitialBannerSelection,
  getUser,
} from '../utils/appSetup';
import { BANNER_AND_USER_GROUP } from '../constants/localStorageKeys';
import Notifications from './Notifications';
import { deleteCookie, setCookie } from '../utils/cookies';
import type { TermsTab } from './TermsAndConditionsModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import usePosthog from '../utils/posthog';
import { termsAndConditionsClick } from '../constants/posthog';
import ProductFruitsContainer from './ProductFruits';
import Popover from './Popover';
import {
  getFormattedUserRole,
  getFormattedUserType,
} from '../utils/adminPanelUtils';

const cache = new LocalStorageCache();
const cacheKey = `${CACHE_KEY_PREFIX}::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUDIENCE}::${AUTH0_SCOPE}`;

const AppContainer = () => {
  const { getAccessTokenSilently, getIdTokenClaims, logout, isAuthenticated } =
    useAuth0();
  const {
    initialiseAppData,
    bannersAndUserGroups,
    user,
    bannerId,
    groupId,
    showAdminControls,
    updateUser,
    updateUserGroupId,
  } = useContext(AppContext);
  const { updateModal, toggleModal, renderComponent } =
    useContext(ModalContext);
  const [loadingAppData, setLoadingAppData] = useState(false);
  const [timeToLogout, setTimeToLogout] = useState(IDLE_TIME_LIMIT_IN_MS);
  const [timeToRefresh, setTimeToRefresh] = useState(0);
  const [logoutTimestamp, setLogoutTimestamp] = useState(
    DateTime.now().plus({ milliseconds: IDLE_TIME_LIMIT_IN_MS }).toMillis()
  );
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);
  const [auth0Expiry, setAuth0Expiry] = useState(0);
  const [productFruitsUserData, setProductFruitsUserData] = useState<
    string | null
  >(null);
  const [optionalTerms, setOptionalTerms] = useState<TermsTab[]>([]);
  const { pathname } = useLocation();
  const isMaintenanceActive = useFeatureFlagEnabled('maintenance-page');
  const navigate = useNavigate();
  const location = useLocation();
  const bc = new BroadcastChannel(BROADCAST_CHANNEL_KEY);
  const isPreview = !!document.cookie.match('Version=Preview');
  const [acceptanceStatus, setAcceptanceStatus] = useState<TERMS_STATUS | null>(
    null
  );
  const posthogEvent = usePosthog();

  const redirectOnAccessRestriction = (
    user: User,
    bannersAndUserGroups: Banner[],
    bannerId: number,
    groupId: number
  ) => {
    initialiseAppData(user, bannersAndUserGroups, bannerId, groupId);
    navigate('/workspace/my-reports');
    toggleModal(false);
  };

  useEffect(() => {
    if (location.pathname === '/' && APP_SECTIONS[0].navigateTo) {
      navigate(APP_SECTIONS[0].navigateTo);
    }
  }, []);

  useEffect(() => {
    if (showLogoutWarning) {
      updateModal({
        type: 'warning',
        title: 'Your session is expiring soon',
        body: `Your session will expire in ${Duration.fromMillis(
          timeToLogout
        ).toFormat("mm'm' ss's'")}. Click Continue to stay logged in.`,
        primaryCTAText: 'Continue Session',
        secondaryCTAText: 'Logout',
        onPrimaryCTAClick: handleContinueSession,
        onSecondaryCTAClick: handleLogout,
      });
    }
  }, [showLogoutWarning, timeToLogout]);

  useEffect(() => {
    !showLogoutWarning && toggleModal(false);
  }, [showLogoutWarning]);

  useEffect(() => {
    const page = location.pathname.match(/[^/]*[^/]/);
    if (page) {
      const capitalizedPage = page[0][0].toUpperCase() + page[0].substring(1);
      document.title = capitalizedPage + ' - ' + PAGE_TITLE;
    }
  }, [location]);

  useEffect(() => {
    if (bannerId && bannersAndUserGroups.length && user && isAuthenticated) {
      const projectId =
        process.env.REACT_APP_ENV === 'prod-new' ? '17622' : '21000';
      const banner = bannersAndUserGroups.find(({ id }) => id === bannerId);
      if (banner) {
        const client = window.location.href.match('localhost')
          ? 'localhost'
          : window.location.href.match(/(https?:\/\/)(.*?)(?=\.)/)?.[2];
        const {
          id: userID,
          user_role,
          user_type,
          supplier_name,
          job_title,
          department,
        } = user;

        posthog.identify(userID, {
          banner: banner.name,
          id: userID,
          user_type: user_type && getFormattedUserType(user_type),
          user_role: user_role && getFormattedUserRole(user_role),
          supplier_name,
          job_title,
          department,
        });
        posthog.capture('Successful login', { userID, banner });
        if (client) {
          posthog.group(client, projectId, { userID, banner });
        }
      }
    }
  }, [isAuthenticated, user, bannerId, bannersAndUserGroups]);

  useEffect(() => {
    const initialiseApp = async () => {
      try {
        setLoadingAppData(true);
        const accessToken = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        if (!idToken) {
          throw new Error('idToken is "undefined"');
        }
        const decodedAccessToken: Auth0AccessToken = jwt_decode(accessToken);
        const user = getUser(decodedAccessToken, idToken, cache, cacheKey);
        const termsAndConditionsStatus = await getAcceptanceStatus(accessToken);
        const optionalTerms = await getUserOptionalTerms(
          accessToken,
          user?.user_type as USER_TYPE
        );
        setOptionalTerms(optionalTerms);
        setAcceptanceStatus(termsAndConditionsStatus.acceptance_status);
        if (
          termsAndConditionsStatus.acceptance_status !== TERMS_STATUS.ACCEPTED
        ) {
          renderComponent(
            <TermsAndConditionsModal
              onClose={() => {
                handleLogout();
              }}
              onSubmit={(status) => {
                navigate('/home');
                setAcceptanceStatus(status);
              }}
              optionalTerms={optionalTerms}
            />,
            'TermsAndConditions'
          );
        }
      } catch {
        setOptionalTerms([]);
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: "There was an error loading the application's data. Please try refreshing your browser. If the issue persists, please contact the helpdesk.",
        });
      } finally {
        setLoadingAppData(false);
      }
    };

    trackUserActivity();
    initialiseApp();
  }, []);

  useEffect(() => {
    const initialiseApp = async () => {
      try {
        setLoadingAppData(true);
        const accessToken = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        if (!idToken) {
          throw new Error('idToken is "undefined"');
        }
        const decodedAccessToken: Auth0AccessToken = jwt_decode(accessToken);
        const user = getUser(decodedAccessToken, idToken, cache, cacheKey);
        const bannersAndUserGroups = await getBannersAndUserGroups(accessToken);

        const { bannerId, groupId, error } =
          getInitialBannerSelection(bannersAndUserGroups);

        if (error) {
          updateModal({
            type: 'warning',
            title: 'Restricted access',
            body: 'You do not have access to this link. You will be redirected to your workspace.',
            onPrimaryCTAClick: () =>
              redirectOnAccessRestriction(
                user,
                bannersAndUserGroups,
                bannerId,
                groupId
              ),
          });
        } else {
          initialiseAppData(user, bannersAndUserGroups, bannerId, groupId);
        }
      } catch (error) {
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: "There was an error loading the application's data. Please try refreshing your browser. If the issue persists, please contact the helpdesk.",
        });
      } finally {
        setLoadingAppData(false);
      }
    };
    if (acceptanceStatus === 'accepted') {
      initialiseApp();
    }
    trackUserActivity();
  }, [acceptanceStatus]);

  useEffect(() => {
    let interval;
    if (user && user.tokenExpiry) {
      const expiringInMS =
        DateTime.fromJSDate(new Date(user.tokenExpiry * 1000)).toMillis() -
        DateTime.fromMillis(Date.now()).toMillis();

      interval = setInterval(() => setTimeToRefresh(expiringInMS), 1000);
    }

    return () => clearInterval(interval);
  }, [timeToRefresh, user]);

  useEffect(() => {
    bc.onmessage = (event) => {
      setLogoutTimestamp(event.data);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToLogout(timeToLogout - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeToLogout]);

  useEffect(() => {
    if (user) {
      const expiringInMS =
        DateTime.fromJSDate(new Date(logoutTimestamp)).toMillis() -
        DateTime.fromMillis(Date.now()).toMillis();

      setTimeToLogout(expiringInMS);
    }
  }, [user, logoutTimestamp]);

  useEffect(() => {
    const interval = setInterval(
      () => setAuth0Expiry(cache.get<WrappedCacheEntry>(cacheKey).expiresAt),
      10000
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (user && user.tokenExpiry !== auth0Expiry) {
      updateUser({
        ...user,
        tokenExpiry: auth0Expiry,
      });
    }
  }, [auth0Expiry]);

  useEffect(() => {
    if (user && user.tokenExpiry !== null) {
      const currentTime = DateTime.fromMillis(Date.now()).toJSDate();

      const expiry = DateTime.fromJSDate(
        new Date(user.tokenExpiry * 1000)
      ).toJSDate();

      if (expiry < currentTime) {
        refreshToken();
      }
    }
  }, [timeToRefresh]);

  useEffect(() => {
    const in5minutes = 300000;
    setShowLogoutWarning(timeToLogout < in5minutes && timeToLogout > 0);
  }, [timeToLogout]);

  const trackUserActivity = () => {
    setTimeToLogout(IDLE_TIME_LIMIT_IN_MS);
    const currentTime = DateTime.now()
      .plus({ milliseconds: IDLE_TIME_LIMIT_IN_MS })
      .toMillis();
    bc.postMessage(currentTime);
  };

  useEffect(() => {
    if (timeToLogout <= 0) {
      logout({ returnTo: window.location.origin });
    }
  }, [timeToLogout]);

  const refreshToken = async () => {
    await getAccessTokenSilently({ ignoreCache: true });
    if (user) {
      updateUser({
        ...user,
        tokenExpiry: cache.get<WrappedCacheEntry>(cacheKey).expiresAt,
      });
    }
  };

  const handleOnChange = async (
    type: 'banner' | 'user-groups',
    selection: { id: number }
  ) => {
    if (!user) {
      return;
    }
    if (type === 'banner') {
      setLoadingAppData(true);
      const groupId = bannersAndUserGroups.find((b) => b.id === selection.id)
        ?.user_groups[0].id;
      if (groupId) {
        initialiseAppData(user, bannersAndUserGroups, selection.id, groupId);
      }
      setLoadingAppData(false);
      sessionStorage.setItem(
        BANNER_AND_USER_GROUP,
        JSON.stringify({ bannerId: selection.id, groupId })
      );
    } else {
      updateUserGroupId(selection.id);
      sessionStorage.setItem(
        BANNER_AND_USER_GROUP,
        JSON.stringify({ bannerId, groupId: selection.id })
      );
    }
    if (pathname.includes('workspace/')) {
      navigate('workspace/my-reports');
    }
  };

  const handleContinueSession = () => {
    refreshToken();
    trackUserActivity();
    setShowLogoutWarning(false);
  };

  const bannerItems = bannersAndUserGroups.map((b) => {
    return { id: b.id, label: b.name };
  });

  const userGroupItems = bannerId
    ? bannersAndUserGroups
        ?.filter((b) => b.id === bannerId)?.[0]
        .user_groups.map((ug) => {
          return { id: ug.id, label: ug.name };
        })
    : [];

  const handleLogout = () => {
    const bc = new BroadcastChannel(BROADCAST_CHANNEL_KEY);
    const currentTime = DateTime.now().toMillis();
    bc.postMessage(currentTime);
    bc.close();
    logout({ returnTo: window.location.origin });
  };

  const handleLogoutClick = () => {
    updateModal({
      type: 'warning',
      title: 'Logout',
      body: 'Are you sure you want to log out?',
      primaryCTAText: 'Logout',
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: handleLogout,
      onSecondaryCTAClick: () => toggleModal(false),
    });
  };

  const handleTermsAndConditionsClick = () => {
    posthogEvent(termsAndConditionsClick);
    renderComponent(
      <TermsAndConditionsModal
        onClose={() => toggleModal(false)}
        isSubmitButtonVisible={false}
        optionalTerms={optionalTerms}
      />,
      'TermsAndConditions'
    );
  };

  const handleShowPreview = () => {
    isPreview ? deleteCookie('Version') : setCookie('Version', 'Preview');
    window.location.reload();
  };

  const previewLabel = isPreview ? 'Show current' : 'Show preview';

  if (isMaintenanceActive) {
    return (
      <Maintenance
        header={MAINTENANCE_HEADER}
        subheader={MAINTENANCE_SUBHEADER}
        hasBackButton={false}
      />
    );
  }

  return (
    <>
      {acceptanceStatus === 'accepted' && (
        <>
          <ProductFruitsContainer
            setProductFruitsUserData={(userData) =>
              setProductFruitsUserData(userData)
            }
          />
          <div
            className="app"
            onMouseOver={trackUserActivity}
            onClick={trackUserActivity}
            onKeyDown={trackUserActivity}
          >
            <div>
              <nav className="app-nav">
                {user && bannerId !== null && groupId !== null && (
                  <div className="app-nav__user-info">
                    {isPreview && (
                      <Popover
                        target={
                          <Button
                            renderIcon={Information}
                            kind="ghost"
                            size="sm"
                          >
                            Preview
                          </Button>
                        }
                        shaded
                      >
                        <div className="app-nav__preview-description">
                          <div>
                            You are currently viewing OneViu in preview mode.
                          </div>
                          <div>
                            As such, some of the functionality is still being
                            fine-tuned.
                          </div>
                          <div>We value your feedback.</div>
                        </div>
                      </Popover>
                    )}

                    <div className="app-nav__dropdowns-wrapper">
                      {userGroupItems?.length > 1 && (
                        <Dropdown
                          id="user-group-dropdown"
                          data-testid="user-group-dropdown"
                          className="app-nav__user-banners"
                          size="sm"
                          label="Dropdown menu options"
                          items={userGroupItems}
                          selectedItem={userGroupItems.find(
                            (group) => group.id === groupId
                          )}
                          onChange={({ selectedItem }) =>
                            handleOnChange('user-groups', selectedItem)
                          }
                        />
                      )}

                      {bannerItems?.length > 1 && (
                        <Dropdown
                          id="banner-dropdown"
                          data-testid="banner-dropdown"
                          className="app-nav__user-banners"
                          size="sm"
                          label="Dropdown menu options"
                          items={bannerItems}
                          selectedItem={bannerItems.find(
                            (b) => b.id === bannerId
                          )}
                          onChange={({ selectedItem }) =>
                            handleOnChange('banner', selectedItem)
                          }
                        />
                      )}
                    </div>
                    <Notifications />
                    <OverflowMenu
                      data-testid="app-user-menu"
                      flipped
                      ariaLabel="User"
                      className="app-nav__user-menu"
                      style={{
                        backgroundImage: `url('https://ui-avatars.com/api/?name=${user.name}&rounded=true&size=32&format=svg&background=1b71d5&color=fff`,
                      }}
                      renderIcon={() => null}
                      focusTrap={false}
                    >
                      {user.showPreview && (
                        <OverflowMenuItem
                          label={previewLabel}
                          itemText={
                            <>
                              <ContentView /> <span>{previewLabel}</span>
                            </>
                          }
                          onClick={handleShowPreview}
                        />
                      )}
                      {showAdminControls && (
                        <OverflowMenuItem
                          label={previewLabel}
                          itemText={
                            <>
                              <SecurityServices /> <span>Admin Panel</span>
                            </>
                          }
                          onClick={() => {
                            navigate('/admin-panel');
                          }}
                        />
                      )}
                      <OverflowMenuItem
                        label="Terms & Policies"
                        itemText={
                          <>
                            <Document /> <span>Terms & Policies</span>
                          </>
                        }
                        onClick={handleTermsAndConditionsClick}
                      />
                      <OverflowMenuItem
                        label="Logout"
                        itemText={
                          <>
                            <Logout /> <span>Logout</span>
                          </>
                        }
                        onClick={handleLogoutClick}
                      />
                    </OverflowMenu>
                  </div>
                )}
              </nav>
            </div>

            <Sidebar productFruitsUserData={productFruitsUserData} />

            <Grid id="content-container" className="app-content-container">
              <Column max={16} xlg={16} lg={16} md={8} sm={4}>
                {getContent(loadingAppData, bannerId)}
              </Column>
            </Grid>
          </div>
        </>
      )}
      {acceptanceStatus !== TERMS_STATUS.ACCEPTED && loadingAppData && (
        <div className="app-spinner-container">
          <span>
            <InlineLoading />
          </span>
        </div>
      )}
    </>
  );
};

const getContent = (loading: boolean, bannerId: number | null) => {
  if (loading) {
    return (
      <InlineLoading
        status="active"
        iconDescription="Loading"
        description="Loading user..."
      />
    );
  } else {
    if (bannerId !== null) {
      return <Outlet />;
    } else {
      return (
        <InlineNotification
          kind="warning"
          lowContrast
          hideCloseButton
          title="Something has gone wrong loading Oneviu."
          subtitle="If this issue persists, please contact our helpdesk."
        />
      );
    }
  }
};

export default AppContainer;
