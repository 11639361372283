import { DatePicker, DatePickerInput, Dropdown } from '@carbon/react';
import { DateTime } from 'luxon';

type DateRange = [Date | undefined, Date | undefined];
export type FilterValue = string | DateRange;
export type FilterState<T> = {
  [key in keyof T]?: FilterValue;
};

export interface FilterConfig<T> {
  type: 'select' | 'date-range';
  columnkey: keyof T;
  label?: string;
  options?: { value?: string; label: string }[];
}

interface DataGridFiltersProps<T> {
  filterState: FilterState<T>;
  filtersConfig: FilterConfig<T>[];
  setFilterState: React.Dispatch<React.SetStateAction<FilterState<T>>>;
}

const DataGridFilters = <T,>({
  filterState,
  filtersConfig,
  setFilterState,
}: DataGridFiltersProps<T>) => {
  const changeFilterValue = (columnkey: keyof T, value: FilterValue) => {
    setFilterState((prevState) => ({
      ...prevState,
      [columnkey]: value,
    }));
  };

  const getFilterComponent = ({
    type,
    label,
    options,
    columnkey,
  }: FilterConfig<T>) => {
    switch (type) {
      case 'select':
        return (
          <Dropdown
            id={`${columnkey.toString()}-dropdown`}
            data-testid={`${columnkey.toString()}-dropdown`}
            titleText={label}
            label={label}
            hideLabel={!label}
            items={options}
            itemToString={(item) => item.label}
            selectedItem={options?.find(
              ({ value, label }) =>
                value === filterState[columnkey] ||
                label === filterState[columnkey]
            )}
            onChange={({ selectedItem }) =>
              changeFilterValue(columnkey, selectedItem.value)
            }
          />
        );

      case 'date-range': {
        const range = (filterState[columnkey] ?? []) as DateRange;
        return (
          <div className="DataGridFilters__date-range">
            <div>
              <DatePicker
                value={[range[0]]}
                datePickerType="single"
                onChange={(date) => {
                  const newRange: DateRange = [...range];
                  newRange[0] = date[0];
                  changeFilterValue(columnkey, newRange);
                }}
                data-testid={`${columnkey.toString()}_start-date-filter`}
                dateFormat="d/m/Y"
                maxDate={
                  range[1] &&
                  DateTime.fromJSDate(range[1]).toFormat('dd/LL/yyyy')
                }
              >
                <DatePickerInput
                  id={`${columnkey.toString()}_date-picker-input-id-start`}
                  placeholder="Start date"
                  data-testid={`${columnkey.toString()}_date-range-filter-start`}
                  labelText=""
                  size="md"
                  autoComplete="off"
                />
              </DatePicker>
            </div>
            <div>
              <DatePicker
                value={[range[1]]}
                datePickerType="single"
                onChange={(date) => {
                  const newRange: DateRange = [...range];
                  newRange[1] = date[0];
                  newRange[1]?.setHours(23, 59, 59);
                  changeFilterValue(columnkey, newRange);
                }}
                data-testid={`${columnkey.toString()}_end-date-filter`}
                dateFormat="d/m/Y"
                minDate={
                  range[0] &&
                  DateTime.fromJSDate(range[0]).toFormat('dd/LL/yyyy')
                }
              >
                <DatePickerInput
                  id={`${columnkey.toString()}_date-picker-input-id-end`}
                  placeholder="End date"
                  data-testid={`${columnkey.toString()}_date-range-filter-end`}
                  labelText=""
                  size="md"
                  autoComplete="off"
                />
              </DatePicker>
            </div>
          </div>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="DataGridFilters">
      {filtersConfig.map((config) => getFilterComponent(config))}
    </div>
  );
};

export default DataGridFilters;
