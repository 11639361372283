import type { Attribute, Product } from '../reducers/AttributeValuesReducer';

export const hasInvalidItems = (items?: Product[] | Attribute[]): boolean =>
  !!items?.some((item) => !item.is_exists);

export const customSortRows = (
  cellA,
  cellB,
  { sortDirection, sortStates }
): number => {
  const [firstValue, secondValue] =
    sortDirection === sortStates.DESC
      ? [cellB?.name ?? cellB, cellA?.name ?? cellA]
      : [cellA?.name ?? cellA, cellB?.name ?? cellB];

  if (typeof firstValue === 'string' && typeof secondValue === 'string') {
    return firstValue.localeCompare(secondValue);
  }
  if (typeof firstValue === 'number' && typeof secondValue === 'number') {
    return firstValue - secondValue;
  }

  return 0;
};
