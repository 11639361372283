import type { AiQuestionLinkedFiles } from './AIAdmin.constants';

export const compareLinkedFiles = (
  initialFiles: AiQuestionLinkedFiles[],
  changedFiles: AiQuestionLinkedFiles[]
): boolean => {
  if (initialFiles.length !== changedFiles.length) {
    return false;
  }

  return initialFiles.every((file, index) => file === changedFiles[index]);
};
