import {
  useContext,
  useCallback,
  type FunctionComponent,
  useState,
  useEffect,
} from 'react';
import type { UploadData } from '../../../reducers/AttributeValuesReducer';
import {
  DataTable,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  InlineLoading,
} from '@carbon/react';
import { WarningFilled } from '@carbon/icons-react';
import { usePagination } from '../../../hooks';
import { AttributeValuesContext } from '../../../providers/AttributeValuesProvider';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import type { DataTableRenderProps } from '../carbon';
import AttributeValuesErrorNotifications from './AttributeValuesErrorNotifications';
import { AppContext } from '../../../providers/AppProvider';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../../api';
import { WebsocketAction } from '../../../types/websocket';
import type { GenericWebsocketMessage } from '../../../types/websocket';
import type { AttributeValuesMessage } from '../types/websocket';
import { AttributeValuesWebsocketStatus } from '../../../types/customAttribute';
import useWebsocketListener from '../../../hooks/useWebsocketListener';
import { ModalContext } from '../../../providers/ModalProvider';
import type { AxiosResponse } from 'axios';

export interface HeaderData {
  key: string;
  header: string;
  is_exists: boolean;
}

export interface RowData {
  id: string;
  product: string;
  [key: string]: string;
}

const isAttributeValuesMessage = (
  message: GenericWebsocketMessage | AttributeValuesMessage
): message is AttributeValuesMessage =>
  message.action === WebsocketAction.Update &&
  message?.data?.type === 'validation_status';

const AttributeValuesGrid: FunctionComponent = () => {
  const { uploadData, uploadMetadata, updateUploadData, updateUploadMetadata } =
    useContext(AttributeValuesContext);
  const { updateModal } = useContext(ModalContext);
  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination({});

  const { groupId, bannerId } = useContext(AppContext);

  const { getAccessTokenSilently } = useAuth0();
  const [isDeleting, setIsDeleting] = useState(false);

  const validationErrorModal: ModalOptions = {
    type: 'error',
    title: 'Something went wrong',
    body: 'An issue occurred while processing your file. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
    isCloseIconVisible: false,
  };

  const onMessage = useCallback(
    (message: GenericWebsocketMessage | AttributeValuesMessage) => {
      if (!isAttributeValuesMessage(message)) {
        return;
      }

      switch (message.data.status) {
        case AttributeValuesWebsocketStatus.Validated: {
          updateUploadMetadata(message.data);
          return;
        }

        case AttributeValuesWebsocketStatus.ValidationFailed: {
          updateModal(validationErrorModal);
          return;
        }
      }
    },
    []
  );

  useWebsocketListener(onMessage);

  const headerData: HeaderData[] = [
    { key: 'product', header: 'Product', is_exists: true },
    { key: 'id', header: 'SKU ID', is_exists: true },
    ...(uploadData?.attributes ?? []).map(({ attribute, is_exists }) => ({
      key: attribute,
      header: capitalizeFirstLetter(attribute),
      is_exists,
    })),
  ];

  const rowData: RowData[] = (uploadData?.products ?? []).map((product) => {
    const productData: RowData = {
      id: product.id,
      product: product.label || '',
      ...(uploadData?.attributes ?? []).reduce((acc, attribute) => {
        const value = attribute.attribute_values[product.id]?.value || '';
        return { ...acc, [attribute.attribute]: value };
      }, {}),
    };
    return productData;
  });

  const deleteNotFoundIds = async (): Promise<void> => {
    try {
      setIsDeleting(true);
      const token = await getAccessTokenSilently();

      const payload = {
        process_id: uploadMetadata?.process_id,
        remove_invalid_products: true,
      };

      await apiRequest(
        `/custom/${bannerId}/user-groups/${groupId}/attribute-files`,
        'PATCH',
        token,
        payload
      );
    } catch (error) {
      updateModal(validationErrorModal);
    }
  };

  const getAttributeData = async (): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();

      if (!uploadMetadata?.file_path) {
        return;
      }

      const response = await apiRequest<AxiosResponse<UploadData>>(
        uploadMetadata.file_path,
        'GET',
        token
      );
      if (response && typeof response === 'object' && 'data' in response) {
        updateUploadData(response.data);
      }
    } catch (error) {
      updateModal(validationErrorModal);
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (!uploadMetadata?.file_path) {
      return;
    }
    getAttributeData();
  }, [uploadMetadata?.file_path]);

  return (
    <div className="AttributeValuesGrid">
      {uploadData ? (
        <>
          <AttributeValuesErrorNotifications
            uploadData={uploadData}
            isDeleting={isDeleting}
            deleteNotFoundIds={deleteNotFoundIds}
            uploadMetadata={uploadMetadata}
          />
          <DataTable rows={rowData} headers={headerData}>
            {({
              rows,
              headers,
              getHeaderProps,
              getRowProps,
              getTableProps,
              getTableContainerProps,
            }: DataTableRenderProps<HeaderData>) => {
              const rowStartIndex = (page - 1) * pageSize;

              const visibleRows = isShowAll
                ? rows
                : rows.slice(rowStartIndex, rowStartIndex + pageSize);

              const getCellContent = (
                cell: CarbonCell<string>,
                hasEmptyProductCell: boolean,
                exists: boolean
              ): TableCell => {
                return (
                  <TableCell
                    style={{
                      ...(!exists && {
                        borderTop: '2px solid #DA1E28',
                        borderBottom: '2px solid #DA1E28',
                      }),
                      background:
                        !exists || hasEmptyProductCell ? '#FFF1F1' : 'none',
                    }}
                    key={cell.id}
                  >
                    {cell.id.includes('product') && cell.value === '' ? (
                      <span className="AttributeValuesGrid__invalid-cell">
                        Product ID not found in database. Edit the number or
                        re-order file
                      </span>
                    ) : (
                      cell.value
                    )}
                  </TableCell>
                );
              };

              return (
                <TableContainer {...getTableContainerProps()}>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header: HeaderData) => {
                          return (
                            <TableHeader
                              key={header.key}
                              {...getHeaderProps({ header })}
                            >
                              <div className="AttributeValuesGrid__header-wrapper">
                                {!header.is_exists && (
                                  <WarningFilled className="AttributeValuesGrid__warning-filled-icon" />
                                )}
                                {header.header}
                              </div>
                            </TableHeader>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleRows.map((row: TableRow) => {
                        const hasEmptyProductCell = row.cells.some(
                          (cell: CarbonCell<string>) =>
                            cell.id.includes('product') && cell.value === ''
                        );
                        return (
                          <TableRow
                            style={{
                              ...(hasEmptyProductCell && {
                                borderTop: '2px solid #DA1E28',
                                borderBottom: '2px solid #DA1E28',
                              }),
                            }}
                            key={row.id}
                            {...getRowProps({ row })}
                          >
                            {row.cells.map((cell: CarbonCell<string>) => {
                              const exists = headerData.some(
                                (item) =>
                                  item.key === cell.info.header &&
                                  item.is_exists
                              );
                              return getCellContent(
                                cell,
                                hasEmptyProductCell,
                                exists
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          </DataTable>
          <Pagination
            backwardText="Previous page"
            forwardText="Next page"
            itemsPerPageText="Items per page:"
            onChange={setPagination}
            page={page}
            pageSize={pageSize}
            pageSizes={pageSizes}
            itemRangeText={getItemRangeText}
            totalItems={rowData.length}
          />
        </>
      ) : (
        <InlineLoading description="Loading custom attributes..." />
      )}
    </div>
  );
};

export default AttributeValuesGrid;
