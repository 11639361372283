export enum CLIENT_FLAGS {
  GENAI = 'gen_ai',
}

export interface ClientFeatureFlag {
  readonly feature: CLIENT_FLAGS;
  readonly is_active: boolean;
}

export interface ClientFeatureFlagsState {
  readonly features: ClientFeatureFlag[];
}

export const initialFlagsState: ClientFeatureFlagsState = {
  features: [],
};

export interface UpdateClientFlagsAction {
  readonly type: 'UPDATE_CLIENT_FLAGS';
  readonly features: ClientFeatureFlag[];
}

const clientFeatureFlagsReducer = (
  state: ClientFeatureFlagsState,
  action: UpdateClientFlagsAction
): ClientFeatureFlagsState => {
  if (action.type === 'UPDATE_CLIENT_FLAGS') {
    return {
      ...state,
      features: action.features,
    };
  } else {
    return state;
  }
};

export default clientFeatureFlagsReducer;
