import { useCallback, useContext } from 'react';
import { ClientFeatureFlagsContext } from '../providers/ClientFeatureFlagProvider';
import type { CLIENT_FLAGS } from '../reducers/ClientFeatureFlagsReducer';

export const useClientFeatureFlags = (): {
  isFlagEnabled: (flag: CLIENT_FLAGS) => boolean;
} => {
  const { features } = useContext(ClientFeatureFlagsContext);

  const isFlagEnabled = useCallback(
    (flag: CLIENT_FLAGS): boolean =>
      features.some(({ feature, is_active }) => feature === flag && is_active),
    [features]
  );

  return { isFlagEnabled: isFlagEnabled };
};
