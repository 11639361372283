import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer } from 'react';
import clientFeatureFlagsReducer, {
  initialFlagsState,
} from '../reducers/ClientFeatureFlagsReducer';
import type { ClientFeatureFlag } from '../reducers/ClientFeatureFlagsReducer';
import { apiRequest } from '../api';
import type { ResponsePayload } from '../components/Fetch';
import { useAuth0 } from '@auth0/auth0-react';

interface ClientFeatureFlagResponse {
  readonly features: ClientFeatureFlag[];
}

export interface ClientFeatureFlagContext extends ClientFeatureFlagResponse {
  readonly updateClientFeatureFlags: (flags: ClientFeatureFlag[]) => void;
}

export const ClientFeatureFlagsContext =
  createContext<ClientFeatureFlagContext>({} as ClientFeatureFlagContext);

interface ClientFeatureFlagsProviderProps {
  children: ReactNode;
}

const ClientFeatureFlagsProvider: FunctionComponent<
  ClientFeatureFlagsProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(
    clientFeatureFlagsReducer,
    initialFlagsState
  );
  const { getAccessTokenSilently } = useAuth0();
  const updateClientFeatureFlags = (features: ClientFeatureFlag[]): void =>
    dispatch({
      type: 'UPDATE_CLIENT_FLAGS',
      features,
    });

  useEffect(() => {
    const getFlags = async () => {
      const token = await getAccessTokenSilently();
      const response = await apiRequest<
        ResponsePayload<ClientFeatureFlagResponse>
      >('configs/client/features', 'GET', token);

      updateClientFeatureFlags(response.data.features);
    };

    getFlags();
  }, []);

  const clientFeatureFlagsContext: ClientFeatureFlagContext = useMemo(
    () => ({
      features: state.features,
      updateClientFeatureFlags,
    }),
    [state]
  );

  return (
    <ClientFeatureFlagsContext.Provider value={clientFeatureFlagsContext}>
      {children}
    </ClientFeatureFlagsContext.Provider>
  );
};

export default ClientFeatureFlagsProvider;
