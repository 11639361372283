/* eslint-disable indent */
import { useContext, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { ToastNotification, InlineLoading } from '@carbon/react';
import { getGridExportStatus } from '../../utils/reportUtils';
import { ReportContext } from '../../providers/ReportProvider';
import { EXPORT_DATA_STATUS } from '../../reducers/ReportReducer';

interface ExportDataGridNotificationProps {
  visualId: number;
  exporting: boolean;
}

const ExportDataGridNotification: FunctionComponent<
  ExportDataGridNotificationProps
> = ({ visualId, exporting }) => {
  const {
    reportConfig: { export_data },
  } = useContext(ReportContext);
  const exportDataStatus = useMemo(
    () => getGridExportStatus(visualId, export_data),
    [visualId, export_data]
  );
  const isNotificationVisible =
    (exportDataStatus && exportDataStatus !== EXPORT_DATA_STATUS.COMPLETED) ||
    exporting;

  const dataGridNotificationToastOptions =
    exportDataStatus === EXPORT_DATA_STATUS.FAILED
      ? {
          subtitle: 'Something went wrong',
          caption: 'Please try again',
          kind: 'error',
        }
      : {
          subtitle: 'Loading...',
          caption: 'This may take up to 3 minutes',
          kind: 'info',
        };

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <ToastNotification
      {...dataGridNotificationToastOptions}
      className={`ExportDataGridNotification ${exportDataStatus?.toLowerCase()}`}
      data-testid="data-grid-notification-toast"
      title="Table Data Export"
      size="sm"
      lowContrast
    >
      {exportDataStatus !== EXPORT_DATA_STATUS.FAILED && (
        <div className="ExportDataGridNotification__loading-indicator">
          <InlineLoading />
        </div>
      )}
    </ToastNotification>
  );
};

export default ExportDataGridNotification;
