/* eslint-disable indent */
import { useContext, useMemo, type FunctionComponent } from 'react';
import { Button } from '@carbon/react';
import { Reset, DataError } from '@carbon/icons-react';
import { ReportContext } from '../../providers/ReportProvider';
import type { VisualData } from '../../reducers/ReportReducer';
import DataGrid, { CustomActionKey } from './DataGrid';
import { NPDContext } from '../../providers/NPDProvider';
import type { NPDGridRowDataType } from '../../reducers/NPDReducer';
import { ModalContext } from '../../providers/ModalProvider';
import type {
  FilterConfig,
  FilterState,
} from '../DataGridFilters/DataGridFilters';
import NoDataPlaceholder from '../ReportTable/NoDataPlaceholder';
import type { ServerExportOptions } from '../DownloadOptions/DownloadOptions';

interface NPDGridProps {
  readonly visualId: number;
  readonly visualData: VisualData<NPDGridRowDataType>;
  readonly dropdownKey: string;
  readonly reportTemplateId: string;
  readonly reportTemplateIndex: number;
  readonly isAuthor: boolean;
  readonly isSupplier: boolean;
  readonly handleGridServerExport?: (
    options: ServerExportOptions
  ) => Promise<void>;
}

const NPDGrid: FunctionComponent<NPDGridProps> = ({
  visualId,
  visualData,
  isAuthor,
  isSupplier,
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
  handleGridServerExport,
}) => {
  const { reportConfig, dropdownSelections, tabIndex, patchVisualsData } =
    useContext(ReportContext);
  const visualDropdownSelection = dropdownSelections?.[tabIndex]?.[visualId];
  const {
    files,
    isResetButtonDisabled,
    isNPDFinderFeaturesEnabled,
    isSaving,
    updateNPDRowFileData,
    resetGridSelection,
  } = useContext(NPDContext);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const { headers, stickyColumns, isVisible, isSearchable, columnWidths } =
    visualData;
  const rowData: NPDGridRowDataType[] =
    files[visualDropdownSelection]?.rows?.[dropdownKey];

  const { headerData, visibleColumns, stickyColumnsData, selectionOptions } =
    useMemo(() => {
      const SELECTION_HEADER = headers?.[dropdownKey].find(
        ({ key }) => key === 'SELECTION'
      );
      if (isNPDFinderFeaturesEnabled && SELECTION_HEADER) {
        const selectionColumnOptions = (
          SELECTION_HEADER.editable && 'options' in SELECTION_HEADER.editable
            ? SELECTION_HEADER.editable.options
            : []
        ) as EditableDropdownOption[];
        const selectionOptions = [
          { value: 'all', label: 'All' },
          ...selectionColumnOptions.map(({ type, value }) => ({
            value: type,
            label: value,
          })),
        ];

        return {
          headerData: headers?.[dropdownKey],
          visibleColumns: isVisible,
          stickyColumnsData: stickyColumns,
          selectionOptions: selectionOptions,
        };
      }

      const headerData = headers?.[dropdownKey].filter(
        ({ id }) => id !== SELECTION_HEADER?.id
      );
      const visibleColumns = isVisible?.filter(
        (id) => id !== SELECTION_HEADER?.id
      );
      const stickyColumnsData = stickyColumns?.filter(
        (id) => id !== SELECTION_HEADER?.id
      );
      return {
        headerData,
        visibleColumns,
        stickyColumnsData,
        selectionOptions: [],
      };
    }, [isNPDFinderFeaturesEnabled, headers, dropdownKey]);

  const onEditRows = ({ rows: newRows }: { rows: NPDGridRowDataType[] }) => {
    updateNPDRowFileData(visualDropdownSelection, dropdownKey, newRows);
  };

  const handleResetButtonClick = (resetFiltersCallback: () => void) => {
    updateModal({
      type: 'info',
      title: 'Are you sure you want to reset all changes made?',
      body: 'The changes will revert to the default empty selection for all products.',
      primaryCTAText: 'Yes, Reset',
      onPrimaryCTAClick: () => {
        resetGridSelection();
        toggleModal(false);
        resetFiltersCallback();
      },
      secondaryCTAText: 'Cancel',
      className: 'NPDProvider__reset-selection-modal',
    });
  };

  const resetButtonAction = (resetFiltersCallback: () => void) => [
    {
      key: CustomActionKey.NPD_RESET_BUTTON,
      element: (
        <Button
          kind="secondary"
          onClick={() => handleResetButtonClick(resetFiltersCallback)}
          disabled={isResetButtonDisabled}
          data-testid="reset-row-selection"
          className="NPDGrid__reset-button"
          iconDescription="Reset"
          hasIconOnly
          tooltipPosition="top"
          aria-label="Reset selection"
        >
          <Reset />
        </Button>
      ),
    },
  ];

  const filtersConfig: FilterConfig<NPDGridRowDataType>[] | null =
    isNPDFinderFeaturesEnabled
      ? [
          {
            type: 'select',
            columnkey: 'SELECTION',
            label: 'Filter by selections',
            options: selectionOptions,
          },
          {
            type: 'date-range',
            columnkey: 'launch_date',
          },
        ]
      : null;

  const getFilterCondition = (
    row: NPDGridRowDataType,
    filterState: FilterState<NPDGridRowDataType>
  ) => {
    const selectionColumn = row['SELECTION'];
    const selectionColumnCondition =
      filterState['SELECTION'] === 'all' ||
      selectionColumn?.type === filterState['SELECTION'];

    const validateLaunchDateColumn = () => {
      const range = filterState['launch_date'] as
        | (Date | undefined)[]
        | undefined;
      const dateValue = (row['launch_date'] as Point<string>)?.value;
      const launchDate = dateValue && new Date(dateValue);
      if (!range || (!range[0] && !range[1]) || !launchDate) {
        return true;
      }
      const [startDate, endDate] = range;
      if (startDate && endDate) {
        return startDate <= launchDate && launchDate <= endDate;
      }
      if (startDate) {
        return startDate <= launchDate;
      }
      if (endDate) {
        return launchDate <= endDate;
      }
      return true;
    };

    return selectionColumnCondition && validateLaunchDateColumn();
  };

  if (!rowData || !headerData) {
    return (
      <NoDataPlaceholder
        title="No Data"
        icon={DataError}
        description={{
          info: (
            <div className="getVisual__no-data-info">
              The selected input combination does not have any associated data.
              <br />
              Try altering your selection to obtain updated results.
            </div>
          ),
        }}
      />
    );
  }

  return (
    <div className="NPDGrid" data-testid="npd-grid">
      <DataGrid
        headerData={headerData}
        rowData={rowData}
        stickyColumns={stickyColumnsData}
        visibleColumns={visibleColumns}
        searchable
        exportedFileName={reportConfig.report_name}
        searchableColumns={isSearchable}
        columnWidths={columnWidths}
        visualData={visualData}
        id={visualId}
        dropdownKey={dropdownKey}
        resizable
        showPagination
        reportConfig={reportConfig}
        isAuthor={isAuthor}
        isSupplier={isSupplier}
        patchVisualsData={patchVisualsData}
        onEditRows={onEditRows}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        customToolbarActions={
          isNPDFinderFeaturesEnabled ? resetButtonAction : []
        }
        filtersConfig={filtersConfig}
        getFilterCondition={
          isNPDFinderFeaturesEnabled ? getFilterCondition : undefined
        }
        disableEditableCell={isSaving}
        handleGridServerExport={handleGridServerExport}
        visualDropdownSelection={visualDropdownSelection}
      />
    </div>
  );
};

export default NPDGrid;
