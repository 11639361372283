import type { AIQuestion } from '../components/AIAdmin/AIAdmin.types';
import {
  UPDATE_AI_ADMIN_QUESTIONS,
  REMOVE_AI_ADMIN_QUESTION,
} from '../constants/reducers';

export const aiAdminInitialState: AIQuestion[] = [];

export interface updateAIQuestions {
  type: typeof UPDATE_AI_ADMIN_QUESTIONS;
  questions: AIQuestion[];
}

export interface RemoveAIQuestion {
  type: typeof REMOVE_AI_ADMIN_QUESTION;
  questionId: string;
}

export type AIAdminAction = updateAIQuestions | RemoveAIQuestion;

export const aiAdminReducer = (
  state: AIQuestion[],
  action: AIAdminAction
): AIQuestion[] => {
  switch (action.type) {
    case REMOVE_AI_ADMIN_QUESTION: {
      return state.filter((question) => question.id !== action.questionId);
    }
    case UPDATE_AI_ADMIN_QUESTIONS: {
      return action.questions;
    }
    default:
      return state;
  }
};
