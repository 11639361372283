/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorFilled } from '@carbon/icons-react';
import type { ResponsePayload } from '../Fetch';
import Fetch from '../Fetch';
import { CACHE_KEY } from '../../constants/api';
import type {
  AIMessage,
  AIQuestionOption,
  GenAIPanelProps,
  GenAIUserQuestion,
  InfoMessage,
  UserMessage,
} from './GenAIPanel.types';
import { AI_RESPONSE_TYPE, DIALOG_POST_AUTHOR } from './GenAIPanel.types';
import '../../styles/components/genAIPanel.scss';
import AI from '../../assets/icons/Ai';
import apiRequest from '../../api';
import { AppContext } from '../../providers/AppProvider';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import type { GenAIResponse } from '../../types/apiTypes';
import GenAIAnswerActions from './GenAIAnswerActions/GenAIAnswerActions';
import usePosthog from '../../utils/posthog';
import {
  aiQuestionItemClickError,
  aiQuestionItemClickSuccess,
} from '../../constants/posthog';
import { TypingLoader } from '../Loading/TypingLoader';
import {
  AiQuestionStatus,
  aiQuestionStatusNames,
} from '../AIAdmin/AIAdmin.constants';

const GenAIPanel: FunctionComponent<GenAIPanelProps> = ({
  selectedCategory,
  configFiles,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { bannerId, queryCache, clearCacheForKey } = useContext(AppContext);
  const { toggleSidePanelExpand } = useContext(SidePanelContext);

  const [questions, setQuestions] = useState<AIQuestionOption[]>([]);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [isQuestionsLoaded, setIsQuestionsLoaded] = useState(false);
  const [dialogList, setDialogList] = useState<
    (AIMessage | UserMessage | InfoMessage)[]
  >([]);

  useEffect(() => {
    if (isQuestionsLoaded) {
      clearCacheForKey(CACHE_KEY.GENAI_QUESTIONS);
      setDialogList([]);
      toggleSidePanelExpand(false);
    }
  }, [bannerId]);

  const posthogEvent = usePosthog();

  const isExtraQuestionsVisible = dialogList.length > 0 && !isAnswerLoading;

  const submitQuestion = async (question: AIQuestionOption): Promise<void> => {
    setIsAnswerLoading(true);
    setDialogList((prevState) => [
      ...prevState,
      {
        owner: DIALOG_POST_AUTHOR.USER,
        post: {
          message: question.question,
        },
      },
    ]);

    const payload: GenAIUserQuestion = {
      question_id: question.id,
      req_question_category: selectedCategory.label,
      config_files: configFiles,
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await apiRequest<ResponsePayload<GenAIResponse>>(
        '/genai',
        'POST',
        token,
        payload
      );

      const { status_code, body } = response.data;
      const { message, request_id } = body ?? {};

      const responseType =
        status_code === 200 ? AI_RESPONSE_TYPE.SUCCESS : AI_RESPONSE_TYPE.ERROR;

      if (responseType === AI_RESPONSE_TYPE.SUCCESS) {
        setDialogList((prevList) => {
          return message
            ? [
                ...prevList,
                {
                  owner: DIALOG_POST_AUTHOR.AI,
                  post: {
                    message,
                    requestId: request_id,
                    type: AI_RESPONSE_TYPE.SUCCESS,
                    question: question.question,
                  },
                },
              ]
            : prevList;
        });
        posthogEvent(aiQuestionItemClickSuccess, {
          question: question.question,
        });
      } else {
        setDialogList((prevList) => {
          return [
            ...prevList,
            {
              owner: DIALOG_POST_AUTHOR.AI,
              post: {
                message: 'Something went wrong. Try again later.',
                type: AI_RESPONSE_TYPE.ERROR,
              },
            },
          ];
        });
        posthogEvent(aiQuestionItemClickError);
      }
    } catch {
      setDialogList((prevList) => [
        ...prevList,
        {
          owner: DIALOG_POST_AUTHOR.AI,
          post: {
            message: 'Something went wrong. Try again later.',
            type: AI_RESPONSE_TYPE.ERROR,
          },
        },
      ]);

      posthogEvent(aiQuestionItemClickError);
    } finally {
      setIsAnswerLoading(false);
    }
  };

  const getQuestionButton = (question: AIQuestionOption): JSX.Element => {
    const isTestingQuestion =
      question.question_status === AiQuestionStatus.Testing;
    return (
      <button
        key={question.id}
        className="GenAIPanel__button"
        disabled={isAnswerLoading}
        onClick={() => submitQuestion(question)}
      >
        {question.question}
        {isTestingQuestion && (
          <span className="GenAIPanel__button--label">
            {aiQuestionStatusNames.testing}
          </span>
        )}
      </button>
    );
  };

  const getQuestions = (): JSX.Element => {
    return questions.length ? (
      <>{questions.map((question) => getQuestionButton(question))}</>
    ) : (
      <p>Sorry, no available questions at the moment</p>
    );
  };

  const getMessage = (
    message: AIMessage | UserMessage | InfoMessage,
    additionalClassName = ''
  ): JSX.Element => {
    const { post, owner } = message;

    const isAIMessage = owner === DIALOG_POST_AUTHOR.AI;
    const isErrorMessage = isAIMessage && post.type === AI_RESPONSE_TYPE.ERROR;
    const messageErrorClassName = isErrorMessage
      ? 'GenAIPanel__message--error'
      : '';

    const isFeedbackVisible = 'question' in post && !!post.question;

    return (
      <div
        key={'requestId' in post ? post.requestId : post.message}
        className={`GenAIPanel__message GenAIPanel__message--${owner} ${messageErrorClassName} ${additionalClassName}`}
      >
        <span className="GenAIPanel__message--content">
          {isAIMessage && (
            <span className="GenAIPanel__message--icon">
              <AI size={16} />
            </span>
          )}
          <span className="GenAIPanel__message--text">
            {isErrorMessage && <ErrorFilled className="error-icon" />}
            {post.message}
          </span>
        </span>
        {isFeedbackVisible && (
          <GenAIAnswerActions message={post.message} question={post.question} />
        )}
      </div>
    );
  };

  return (
    <div className="GenAIPanel">
      <Fetch
        initialData={questions}
        alwaysFetchOnMount={
          !questions.length || !queryCache[CACHE_KEY.GENAI_QUESTIONS]
        }
        onReceiveData={(data: AIQuestionOption[]) => {
          if (data) {
            setQuestions(data);
            setIsQuestionsLoaded(true);
          }
        }}
        apiUrl={`/genai/questions?tdp=${bannerId}`}
        cacheKey={CACHE_KEY.GENAI_QUESTIONS}
        loadingMessage="Loading OneViu AI data..."
      >
        {questions.length > 0 && (
          <div
            className={`GenAIPanel__header ${dialogList.length > 0 ? 'GenAIPanel__header--active' : ''}`}
          >
            <span className="GenAIPanel__header--image">
              <AI size={40} />
            </span>
            <div className="GenAIPanel__header--description">
              <p className="GenAIPanel__header--title">
                Hello, I am OneViu AI.
              </p>
              <p className="GenAIPanel__header--text">
                Let’s dive into your data!
              </p>
            </div>
          </div>
        )}
        <div className="GenAIPanel__posts">
          {questions.length > 0 &&
            getMessage(
              {
                owner: DIALOG_POST_AUTHOR.AI,
                post: {
                  message:
                    'I can help you work more efficiently. You can summarise your data, understand your category’s performance, and gather insights. Here are some common questions you can try.',
                  type: AI_RESPONSE_TYPE.SUCCESS,
                },
              },
              'GenAIPanel__message--full-width'
            )}
          {!dialogList.length && isQuestionsLoaded && getQuestions()}
          {dialogList.map((dialog) => getMessage(dialog))}
          {isAnswerLoading && (
            <div
              className="GenAIPanel__message GenAIPanel__message--ai GenAIPanel__message--ai__loader"
              data-testid="ai-message-loader"
            >
              <span className="GenAIPanel__message--icon">
                <AI size={16} />
              </span>
              <TypingLoader />
            </div>
          )}
          {isExtraQuestionsVisible && (
            <div className="GenAIPanel__extra" data-testid="ai-extra-questions">
              {getMessage({
                owner: DIALOG_POST_AUTHOR.AI,
                post: {
                  message: 'What would you like to know next?',
                  type: AI_RESPONSE_TYPE.SUCCESS,
                },
              })}
              {questions.map((question) => getQuestionButton(question))}
            </div>
          )}
        </div>
        <div className="GenAIPanel__footer">
          <p className="GenAIPanel__footer--text">
            OneViu AI can make mistakes, so double check the responses.
          </p>
        </div>
      </Fetch>
    </div>
  );
};

export default GenAIPanel;
