import { Warning, Download, TrashCan } from '@carbon/icons-react';
import { Button, InlineLoading } from '@carbon/react';
import { hasInvalidItems } from '../../../utils/attributeUtils';
import type {
  UploadData,
  UploadMetadata,
} from '../../../reducers/AttributeValuesReducer';
import { useContext, type FunctionComponent } from 'react';
import { ModalContext } from '../../../providers/ModalProvider';

interface AttributeValuesErrorNotificationsProps {
  uploadData: UploadData;
  uploadMetadata: UploadMetadata | null;
  isDeleting: boolean;
  deleteNotFoundIds: () => void;
}

const AttributeValuesErrorNotifications: FunctionComponent<
  AttributeValuesErrorNotificationsProps
> = ({ uploadData, uploadMetadata, isDeleting, deleteNotFoundIds }) => {
  const { updateModal } = useContext(ModalContext);

  const downloadSkus = (): void => {
    if (!uploadMetadata?.invalid_file_path) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue downloading the invalid SKUs CSV file. Please try again.',
      });
      return;
    }

    window.location.href = uploadMetadata.invalid_file_path;
  };

  return (
    <div className="AttributeValuesErrorNotifications">
      <div>
        {hasInvalidItems(uploadData?.products) && (
          <div className="AttributeValuesGrid__product-notification-wrapper">
            <div className="AttributeValuesGrid__notification">
              <Warning
                size={18}
                className="AttributeValuesGrid__warning-icon"
              />
              Product not found in your database. Please re-upload the file with
              SKUs so they match existing product ID’s.
            </div>
            <div className="AttributeValuesGrid__notification-buttons">
              <Button
                kind="primary"
                renderIcon={isDeleting ? InlineLoading : TrashCan}
                onClick={deleteNotFoundIds}
                size="sm"
                disabled={isDeleting}
                className="has-icon"
              >
                {isDeleting ? 'Deleting...' : 'Delete IDs not found'}
              </Button>

              <Button
                kind="secondary"
                renderIcon={Download}
                onClick={downloadSkus}
                size="sm"
                disabled={false}
                className="has-icon"
              >
                Download list of Invalid SKUS
              </Button>
            </div>
          </div>
        )}
        {hasInvalidItems(uploadData?.attributes) && (
          <div className="AttributeValuesGrid__notification">
            <Warning size={18} className="AttributeValues__warning-icon" />
            Attribute name doesn’t match any existing attribute group name.
            Please re-upload the file with the correct attribute name as the
            column header.
          </div>
        )}
      </div>
    </div>
  );
};

export default AttributeValuesErrorNotifications;
