import type { FunctionComponent } from 'react';
import { Button, ToastNotification } from '@carbon/react';

export interface ErrorNotificationType {
  title: string;
  subtitle?: string;
  button?: {
    label: string;
    onClick: () => void;
  };
}

const DataErrorNotification: FunctionComponent<ErrorNotificationType> = ({
  title,
  subtitle,
  button,
}) => {
  return (
    <ToastNotification
      aria-label="error notification"
      kind="warning"
      lowContrast
      title={title}
      subtitle={subtitle}
      className="DataErrorNotification__toast"
    >
      {button && (
        <Button onClick={button.onClick} kind="secondary" as="div" size="sm">
          {button.label}
        </Button>
      )}
    </ToastNotification>
  );
};

export default DataErrorNotification;
